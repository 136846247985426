import React from 'react';
import ReactDOM from 'react-dom';

interface ToolTipProps {
  data: string;
}
interface ToolTipState {
  visible: boolean;
  x: number;
  y: number;
  type: string;
  data: string;
}

class ToolTip extends React.Component<ToolTipProps, ToolTipState> {
  constructor(props: any) {
    super(props);
    this.state = { visible: false, x: 0, y: 0, type: 'none', data: '' };
  }

  pastShow(hoverRect: any) {
    // position the tooltip after showing it
    const ttNode = ReactDOM.findDOMNode(this) as HTMLElement;
    if (ttNode !== null) {
      const x = hoverRect.x + 0.5 * hoverRect.width,
        y = hoverRect.y + 1.5 * hoverRect.height;

      const newState = { type: 'bottom', x: x, y: y };
      this.setState(newState);
    }
  }

  render() {
    const { state } = this;
    const visibility = state.visible == true ? 'on' : 'off';
    const style = {
      left: state.x + 'px',
      top: state.y + 'px'
    };

    const classNames = {};
    if (state.type != null && state.type != 'none') {
      classNames[state.type] = true;
    }
    classNames[visibility] = true;

    return (
      <div id="tooltip" className={Object.keys(classNames).join(' ')} style={style}>
        <div className="tooltip-arrow"></div>
        <div className="tooltip-content">{this.state.data}</div>
      </div>
    );
  }

  show(hoverRect: any, data: string) {
    data && this.setState({ visible: true, data: data }, () => this.pastShow(hoverRect));
  }

  hide() {
    this.setState({ visible: false });
  }
}

export default ToolTip;
