import React from 'react';
import { connect } from 'react-redux';
import { Option, option } from 'ts-option';

import AceEditor from 'react-ace';
import 'ace-builds/src-noconflict/theme-monokai';
import 'ace-builds/src-noconflict/mode-sql';

import fixedEncodeURIComponent from 'common/js_utils/fixedEncodeURIComponent';
import { fetchTranslation } from 'common/locale';
import Button from 'common/components/Button';
import { Modal, ModalHeader, ModalContent, ModalFooter } from 'common/components/AccessibleModal';
import SoQLMode from 'common/components/SoQLEditor/SoQLAceMode';

import * as Actions from '../redux/actions';
import { AppState, OpenModalType } from '../redux/store';
import { MAX_FRONTEND_URL_SIZE } from 'common/utilities/Constants';
import { getCurrentDomain } from 'common/currentDomain';

const t = (k: string) => fetchTranslation(k, 'shared.explore_grid.saved_query_modal');

export type SavedQueryModalProps = SavedQueryModalStateProps & SavedQueryModalDispatchProps;
type SavedQueryModalStateProps = {
  isOpen: boolean;
  maybeSavedQueryText: Option<string>;
  maybeParentUid: Option<string>;
};
type SavedQueryModalDispatchProps = {
  onDismiss: () => void;
};

type Editor = any;


export function SavedQueryModal(props: SavedQueryModalProps) {
  const { onDismiss, isOpen, maybeSavedQueryText, maybeParentUid } = props;

  if (!maybeSavedQueryText.isDefined || !maybeParentUid.isDefined) {
    return null;
  }
  const parentUid = maybeParentUid.get;
  const savedQueryText = maybeSavedQueryText.get;

  let parentLink = `/d/${parentUid}/explore/query/${fixedEncodeURIComponent(savedQueryText)}`;
  const urlBase = 'https://' + (getCurrentDomain() || '');
  if ((urlBase.length + parentLink.length) > MAX_FRONTEND_URL_SIZE ) {
    parentLink = `/d/${parentUid}/explore/`;
  }


  const bootstrapEditor = (editor: Editor) => {
    editor.getSession().setMode(new SoQLMode());
  };

  const defaultEditorProps = {
    name: 'saved-query-editor',
    mode: 'sql',
    width: '100%',
    theme: 'monokai',
    fontSize: 15,
    minLines: 2,
    maxLines: 30,
    showGutter: false,
    showPrintMargin: false,
    focus: true,
    readOnly: true,
    wrapEnabled: true,
    onLoad: bootstrapEditor
  };

  return (
    <Modal onRequestClose={onDismiss} isOpen={isOpen} id="saved-query-modal">
      <ModalHeader onDismiss={onDismiss}>
        <h2>{t('title')}</h2>
      </ModalHeader>
      <ModalContent>
        <div>
          {t('body')}
          <div className="code-window">
          <AceEditor
            {...defaultEditorProps} readOnly={true} value={savedQueryText}
          />
          </div>
          <p>
            <a href={parentLink}>{t('parent_link')}</a>
          </p>
        </div>
      </ModalContent>
      <ModalFooter>
        <Button className="close-button" onClick={onDismiss}>
          {t('close')}
        </Button>
      </ModalFooter>
    </Modal>
  );
}

const mapStateToProps = (state: AppState): SavedQueryModalStateProps => {
  return {
    isOpen: state.openModal.type == OpenModalType.SAVED_QUERY,
    maybeSavedQueryText: option(state.view.queryString),
    maybeParentUid: state.parentInfo.mainParent.map(v => v.id)
  };
};

const mapDispatchToProps = (dispatcher: Actions.Dispatcher) => {
  return {
    onDismiss: () => dispatcher(Actions.closeModal())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SavedQueryModal);
