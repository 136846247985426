import React from 'react';
import { connect } from 'react-redux';
import { ForgeToast } from '@tylertech/forge-react';

import { AppState, ToastState } from '../redux/store';
import * as Actions from '../redux/actions';
import { Dispatcher } from '../redux/actions';

interface DispatchProps {
  clearToast: () => void;
}

type StateProps = ToastState;
type Props = StateProps & DispatchProps;

class ExploreGridForgeToast extends React.Component<Props> {
  render() {
    const { isOpen, clearToast, icon, message } = this.props;
    const toastOptions = {
      placement: 'top',
      id: 'explore-grid-forge-toast',
      ...(this.props.duration) && {duration: this.props.duration}
    };

    return (
      <ForgeToast
        open={isOpen}
        onDismiss={clearToast}
        options={toastOptions}
      >
        <div className='message-container'>
          <span className='toast-icon'>{icon.nonEmpty && icon.get}</span>
          <span>{message.getOrElseValue('')}</span>
        </div>
      </ForgeToast>
    );
  }
}

const mapStateToProps = (state: AppState): StateProps => {
  return {
    ...state.toastState
  };
};

const mapDispatchToProps = (dispatch: Dispatcher): DispatchProps => {
  return {
    clearToast: () => {
      dispatch(Actions.clearToast());
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ExploreGridForgeToast);
