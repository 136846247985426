import React from 'react';
import cx from 'classnames';
import _ from 'lodash';
import { Option } from 'ts-option';
import I18n from 'common/i18n';
import { ViewContext } from 'common/types/compiler';
import { View } from 'common/types/view';

const t = (k: string) => I18n.t(k, { scope: 'shared.explore_grid.visual_column_manager' });

interface Props {
  viewContexts: ViewContext;
  selected?: keyof ViewContext;
  onChooseContext: (contextId: string | null) => void;
}

type CompareByViewName = (a: [keyof ViewContext, View], b: [keyof ViewContext, View]) => number;
const compareByViewName: CompareByViewName = ([a_c, a_v], [b_c, b_v]) => b_v.name > a_v.name ? -1 : 1;

class ViewSelectionBar extends React.Component<Props> {
  render() {
    const { onChooseContext, selected: selectedViewContextId, viewContexts } = this.props;

    if (_.size(viewContexts) < 2) {
      return null;
    }

    return (
      <div className="view-selection">
        <div
          className={cx('view-selector', { selected: selectedViewContextId === undefined })}
          onClick={() => onChooseContext(null)}>
          {t('all_columns')}
        </div>
        {Object.entries(viewContexts).sort(compareByViewName).map(([contextId, view]) =>
          <div
            key={contextId}
            className={cx('view-selector', { selected: contextId === selectedViewContextId })}
            onClick={() => onChooseContext(contextId)}>
            {view.name}
          </div>
        )}
      </div>
    );
  }
}

export default ViewSelectionBar;
