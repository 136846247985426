import React from 'react';
import { connect } from 'react-redux';
import { ForgeMenu, ForgeButton, ForgeIcon } from '@tylertech/forge-react';
import { IMenuOption, IMenuSelectEventData } from '@tylertech/forge';
import I18n from 'common/i18n';
import { ClientContextVariable } from 'common/types/clientContextVariable';
import { getParameterFunction } from 'common/core/client_context_variables';
import { getForgeIconNameForDataType } from 'common/views/dataTypeMetadata';
import { Tab } from 'common/explore_grid/types';
import { AppState, OpenModalType, Query } from '../redux/store';
import * as Actions from '../redux/actions';
import { Dispatcher } from '../redux/actions';
import { none } from 'ts-option';

const t = (k: string, options = {}, scope = 'shared.explore_grid.parameter_insert_menu') => I18n.t(k, { ...options, scope });

interface Cursor {
  row: number;
  column: number;
}

interface ExternalProps {
  tab: Tab;
  parameters: ClientContextVariable[];
  canModifyParameters: boolean;
}
interface StateProps {
  query: Query;
  getCursor: () => Cursor;
  setCursor: (row: number, column: number) => void;
  uid: string;
}

interface DispatchProps {
  setQueryTextAndRecompile: (q: string) => void;
  openParameterModal: () => void;
}

type Props = ExternalProps & {
  insertParameter: (parameter: ClientContextVariable) => void;
  openParameterModal: () => void;
};

enum OptionValueType {
  INSERT_PARAMETER,
  CREATE_NEW
}

type OptionValue = {
  type: OptionValueType;
  action: () => void;
};

export class InsertParameterMenu extends React.Component<Props> {

  getOptions() {
    const paramOptions: IMenuOption[] = this.props.parameters.map(p => {
      const iconType = getForgeIconNameForDataType(p.dataType);
      return {
        value: {
          type: OptionValueType.INSERT_PARAMETER,
          action: () => this.props.insertParameter(p)
        },
        label: p.name,
        leadingIcon: iconType,
        leadingIconClass: 'tyler-icons-ext',
        leadingIconType: 'component'
      };
    });

    const createNewOpt: IMenuOption[] = [{
      value: '',
      label: '',
      divider: true
    },
    {
      value: {
        type: OptionValueType.CREATE_NEW,
        action: () => { this.props.openParameterModal(); }
      },
      label: t('create_new'),
    }];
    return this.props.canModifyParameters ? paramOptions.concat(createNewOpt) : paramOptions;
  }

  onMenuSelect({ detail }: CustomEvent<IMenuSelectEventData>) {
    const value = detail.value as OptionValue;
    value.action();
  }

  render() {
    const { tab } = this.props;
    const options = this.getOptions();
    if (tab === Tab.Code && options.length !== 0) {
      return (
        <span className="datasource-header-button datasource-left-subtitle datasource-insert-parameter">
          <ForgeMenu
            placement={'bottom'}
            className="insert-parameter-menu"
            options={options}
            highlightFirst={false}
            on-forge-menu-select={this.onMenuSelect}
            popupClasses='insert-parameter-options'>
            <ForgeButton>
              <button id='insert-parameter-menu-btn'>
                <ForgeIcon name="add" />
                <span className="insert-parameter-text">
                  {t('insert_parameter')}
                </span>
              </button>
            </ForgeButton>
          </ForgeMenu>
        </span>
      );
    }
    return null;
  }
}

const mapStateToProps = (state: AppState): StateProps => {
  return {
    query: state.query,
    getCursor: () => {
      return state.soqlEditorInfo.editorInstance.map(editor => {
        return editor.getSession().selection.getCursor();
      }).getOrElseValue({row: 0, column: 0});
    },
    setCursor: (row: number, column: number) => {
      state.soqlEditorInfo.editorInstance.map(editor => {
        editor.focus();
        editor.getSession().selection.moveTo(row, column);
        editor.centerSelection();
      });
    },
    uid: state.fourfour
  };
};

const mapDispatchToProps = (dispatch: Dispatcher): DispatchProps => {
  return {
    setQueryTextAndRecompile: (q) => {
      dispatch(Actions.setQueryText(q));
      dispatch(Actions.compileText(q));
    },
    openParameterModal: () => {
      dispatch(Actions.openModal(OpenModalType.NEW_PARAMETER, none));
    }
  };
};

export const mergeProps = (stateProps: StateProps, dispatchProps: DispatchProps, ownProps: ExternalProps) => {
  return {
    ...ownProps,
    openParameterModal: dispatchProps.openParameterModal,
    insertParameter: (parameter: ClientContextVariable) => {
      stateProps.query.text.map(queryText => {
        const insertableParameter = getParameterFunction(parameter.name, parameter.viewId);
        const cursorPosition = stateProps.getCursor();
        const newQuery = queryText.split(/\n/).map((row, i) => {
          if (i === cursorPosition.row) {
            return `${row.substring(0, cursorPosition.column)}${insertableParameter}${row.substring(cursorPosition.column)}`;
          } else {
            return row;
          }
        }).join('\n');
        dispatchProps.setQueryTextAndRecompile(newQuery);
        stateProps.setCursor(cursorPosition.row, cursorPosition.column + insertableParameter.length);
      });
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(InsertParameterMenu);
