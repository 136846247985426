import { ForgeButton, ForgeBanner } from '@tylertech/forge-react';
import { fetchTranslation } from 'common/locale';
import React from 'react';
import { connect } from 'react-redux';
import { Dispatcher, undockEditor } from '../redux/actions';
import { AppState } from '../redux/store';

const t = (k: string) => fetchTranslation(k, 'shared.explore_grid.grid_redock_banner');

interface DispatchProps {
  undockEditor: (undocked: boolean) => void;
}

interface StateProps {
  undocked: boolean;
}

export type MergedProps = StateProps & {
  undockEditor: (undocked: boolean) => void;
};

export class GridRedockBanner extends React.Component<MergedProps> {
  render() {
    return (
      <ForgeBanner canDismiss={false}>
        <div>
          {t('editor_currently_undocked')}
        </div>
        <ForgeButton type="outlined" slot="button">
          <button type="button"
            onClick={() => this.props.undockEditor(false)}>
            <span>{t('redock')}</span>
          </button>
        </ForgeButton>
      </ForgeBanner>
    );
  }
}

const mapStateToProps = (state: AppState): StateProps => ({
  undocked: state.undocked
});

const mapDispatchToProps = (dispatch: Dispatcher): DispatchProps => {
  return {
    undockEditor: (undocked: boolean) => {
      dispatch(undockEditor(undocked));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(GridRedockBanner);
