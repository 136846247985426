import React, { PropsWithChildren } from 'react';
import { Option } from 'ts-option';
import { fetchTranslation } from 'common/locale';
import { factorOption } from 'common/either';
import { QueryAnalysisFailed, QueryCompilationFailed } from 'common/types/compiler';
import { analysisFailure, compilationFailure } from '../../lib/selectors';
import { whichAnalyzer } from '../../lib/feature-flag-helpers';
import { Query } from '../../redux/store';
import Compiling from '../Compiling';
import CompilerError from './CompilerError';
import { RemoteStatusInfo, selectors as SelectRemoteStatus } from '../../redux/statuses';

const t = (k: string) => fetchTranslation(k, 'shared.explore_grid.compiler_errors');

type Props = PropsWithChildren<{
  remoteStatusInfo: Option<RemoteStatusInfo>;
  query: Query;
  requireCompilationSuccess?: boolean;
}>;

const WithHandlingOfNonVisualStates: React.FC<Props> = ({ query, remoteStatusInfo, requireCompilationSuccess, children }) => {
  if (SelectRemoteStatus.compilingCandidateQuery(remoteStatusInfo).nonEmpty) {
    return <Compiling />;
  }

  const failure = factorOption<QueryCompilationFailed, QueryAnalysisFailed>(whichAnalyzer(
    () => compilationFailure(query.compilationResult),
    () => analysisFailure(query.analysisResult)
  )());

  if (requireCompilationSuccess && failure.isDefined) {
    return (
      <div className="alert error">
        {t('compilation_failed')}
        <CompilerError error={failure.get} />
      </div>
    );
  }

  return <div>{children}</div>;
};

export default WithHandlingOfNonVisualStates;
