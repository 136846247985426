import React from 'react';
import * as _ from 'lodash';
import { Option, none, option, some } from 'ts-option';
import { Either, left as buildLeft, right as buildRight } from 'common/either';
import { AnalyzedSelectedExpression, Expr, NamePosition, Scope, SoQLType, TypedExpr, UnAnalyzedAst, UnAnalyzedSelectedExpression, TypedSelect, isColumnRef, isFunCall, NamedExpr } from 'common/types/soql';
import { ProjectionInfo, ProjectionInfoNA, ViewColumnColumnRef } from '../../lib/selectors';
import { replaceAt } from 'common/util';
import { containsAggregate, existingFieldNames, existingFieldNamesNA, hasAggregates, SelectionItem } from '../../lib/soql-helpers';
import { usingNewAnalysisEndpoint } from '../../lib/feature-flag-helpers';
import { fetchTranslation } from 'common/locale';
import { validateColumnFieldName } from 'common/column/utils';
import { buildSelection, buildSelectionNA, dropOrderBys, dropOrderBysNA, updateHaving, updateHavingNA } from '../../components/VisualGroupAggregateEditor';
import { CompileAST } from '../visualContainer';
import ExpressionEditor from '../VisualExpressionEditor';
import SubtitleWithHelper from '../SubtitleWithHelper';
import AggregateAddExpr, { EditAggregateName } from '../AggregateAddExpr';
import AggregateModal from './AggregateModal';
import { ClientContextVariable } from 'common/types/clientContextVariable';
import { ForgeButton, ForgeIcon } from '@tylertech/forge-react';
import { EditableExpression, EditableExpressionNA, Eexpr, EexprNA } from 'common/explore_grid/types';


const t = (k: string) => fetchTranslation(k, 'shared.explore_grid.visual_aggregates');

export interface VisualAggregateProps {
  ast: Either<UnAnalyzedAst, TypedSelect>;
  columns: ViewColumnColumnRef[];
  parameters: ClientContextVariable[];
  eexpr: Either<Eexpr<Expr, TypedExpr>, EexprNA<TypedExpr>>;
  analyzedName: string;
  unAnalyzedName: Option<string>;
  projectionInfo: Either<ProjectionInfo, ProjectionInfoNA>;
  querySucceeded: boolean;
  scope: Scope;
  onUpdate: (newExpr: TypedExpr, name: Option<NamePosition>) => void;
  onRemove: (name: string) => void;
  showModal: boolean;
  dismiss: () => void;
  deleteAggregate: () => void;
}

interface VisualAggregateState {
  name: Option<string>;
  errors: string[];
}

// Export needed for testing
export class VisualAggregate extends React.Component<VisualAggregateProps, VisualAggregateState> {
  state: VisualAggregateState = { name: some(this.props.analyzedName), errors: [] };

  onNameChange = (text: string) => {
    const { analyzedName, ast, eexpr, onUpdate, projectionInfo, scope } = this.props;
    const usedFieldNames = ((pi, a) => {
      if (usingNewAnalysisEndpoint()) {
        return existingFieldNamesNA(a.right, pi.right, scope);
      } else {
        return existingFieldNames(a.left, pi.left, scope);
      }
    })(projectionInfo, ast).filter(fieldName => fieldName != analyzedName);
    const errors = validateColumnFieldName(text, usedFieldNames);
    const notMatching = text !== analyzedName; // text is not the same as the current api field name
    if (_.isEmpty(errors) && notMatching) {
      const newName = {
        name: text,
        position: {
          column: 1,
          line: 1
        }
      } as NamePosition;
      onUpdate(eexpr.fold(eexprOA => (eexprOA.untyped as TypedExpr), eexprNA => eexprNA.expr), some(newName)); // That coercion is bad. :(
    }
    this.setState({ errors });
  };

  onUpdate = (newExpr: Either<Expr, TypedExpr>) => {
    this.props.onUpdate(newExpr.foldEither(e => e) as TypedExpr, none); // That coercion is bad. :(
  };

  render() {
    const { columns, eexpr, onRemove, projectionInfo, querySucceeded, scope, parameters } = this.props;
    const name = this.state.name.getOrElseValue('');

    return (
      <div className="aggregate-by">
        <div className="aggregate-expression">
          <ExpressionEditor
            scope={scope}
            isTypeAllowed={(st: SoQLType) => true}
            eexpr={eexpr}
            columns={columns}
            parameters={parameters}
            update={this.onUpdate}
            remove={() => onRemove(name)}
            projectionInfo={projectionInfo}
            querySucceeded={querySucceeded}
            showRemove={false}
            showKebab />
          <EditAggregateName
            value={this.state.name.getOrElseValue('')}
            onChange={this.onNameChange}
            errors={this.state.errors} />
          {this.props.showModal && <AggregateModal
            onDismiss={this.props.dismiss}
            deleteAggregate={this.props.deleteAggregate}
            apiFieldName={name}/>}
        </div>
      </div>
    );
  }
}

export function onUpdateAggregate(
  astEither: Either<UnAnalyzedAst, TypedSelect>,
  selectedExpressionsEither: Either<EditableExpression<UnAnalyzedSelectedExpression, AnalyzedSelectedExpression>[], SelectionItem[]>,
  index: number,
  newExpr: TypedExpr,
  name: Option<NamePosition>
): UnAnalyzedAst {
  if (usingNewAnalysisEndpoint()) {
    const ast = astEither.right, selectedExpressions = selectedExpressionsEither.right;
    const selectedExpr: NamedExpr = {
      expr: newExpr,
      name: name.orNull
    };
    const droppedSelections = [selectedExpressions[index]];
    return {
      ...ast,
      selection: {
        ...ast.selection,
        exprs: replaceAt(ast.selection.exprs, selectedExpr, index)
      },
      order_bys: dropOrderBysNA(droppedSelections, ast.order_bys),
      having: updateHavingNA(droppedSelections, ast.having)
    };
  } else {
    const ast = astEither.left, selectedExpressions = selectedExpressionsEither.left;
    const selectedExpr: UnAnalyzedSelectedExpression = {
      expr: newExpr,
      name: name.orNull
    };
    const droppedSelections = [selectedExpressions[index]];
    return {
      ...ast,
      selection: {
        ...ast.selection,
        exprs: replaceAt(ast.selection.exprs, selectedExpr, index)
      },
      order_bys: dropOrderBys(droppedSelections, ast.order_bys),
      having: updateHaving(droppedSelections, ast.having)
    };
  }
}

export function onRemoveAggregate(
  astEither: Either<UnAnalyzedAst, TypedSelect>,
  selectedExpressionsEither: Either<EditableExpression<UnAnalyzedSelectedExpression, AnalyzedSelectedExpression>[], SelectionItem[]>,
  index: number,
  columns: ViewColumnColumnRef[]
): UnAnalyzedAst | TypedSelect {
  if (usingNewAnalysisEndpoint()) {
    const ast = astEither.right, selectedExpressions = selectedExpressionsEither.right;
    const droppedSelections = [selectedExpressions[index]];
    return ({
      ...ast,
      selection: buildSelectionNA(selectedExpressions.filter((_unused, i) => i !== index), ast.group_bys, columns),
      order_bys: dropOrderBysNA(droppedSelections, ast.order_bys),
      having: updateHavingNA(droppedSelections, ast.having)
    });
  } else {
    const ast = astEither.left, selectedExpressions = selectedExpressionsEither.left;
    const droppedSelections = [selectedExpressions[index]];
    return ({
      ...ast,
      selection: buildSelection(selectedExpressions.map(({ untyped: expr }) => expr).filter((_unused, i) => i !== index), ast.group_bys, columns),
      order_bys: dropOrderBys(droppedSelections, ast.order_bys),
      having: updateHaving(droppedSelections, ast.having)
    });
  }
}

interface VisualAggregateListProps {
  ast: Either<UnAnalyzedAst, TypedSelect>;
  columns: ViewColumnColumnRef[];
  parameters: ClientContextVariable[];
  compileAST: CompileAST;
  projectionInfo: Either<ProjectionInfo, ProjectionInfoNA>;
  querySucceeded: boolean;
  scope: Scope;
  selectedExpressions: Option<Either<EditableExpression<UnAnalyzedSelectedExpression, AnalyzedSelectedExpression>[], SelectionItem[]>>;
}

interface VisualAggregateListState {
  showAggregateAddExpr: boolean;
  showModalIndex: Option<number>;
}

export default class VisualAggregateList extends React.Component<VisualAggregateListProps, VisualAggregateListState> {
  constructor(props: VisualAggregateListProps) {
    super(props);
    this.state = {
      showAggregateAddExpr: !hasAggregates(props.ast.foldEither(a => a), props.scope),
      showModalIndex: none
    };
  }

  onShowAggregateAddExpr = () => {
    this.setState({ showAggregateAddExpr: true });
  };

  onHideAggregateAddExpr = () => {
    this.setState({ showAggregateAddExpr: false });
  };

  hideModal = () => {
    this.setState({ showModalIndex: none });
  };

  render() {
    const { ast, columns, compileAST, parameters, projectionInfo, querySucceeded, scope } = this.props;
    const help = (<p className="help-message forge-typography--body2" dangerouslySetInnerHTML={{__html: t('help')}}></p>);
    const items = this.props.selectedExpressions.map(selectedExpressions => {
      const renderExpression = ({ selectedExpression, index}: { selectedExpression: Either<EditableExpression<UnAnalyzedSelectedExpression, AnalyzedSelectedExpression>, SelectionItem>, index: number }) => {
        const onUpdate = (newExpr: TypedExpr, name: Option<NamePosition>) => {
          const newAst = onUpdateAggregate(ast, selectedExpressions, index, newExpr, name);
          if (hasAggregates(newAst, scope)) {
            this.onHideAggregateAddExpr();
          } else {
            this.onShowAggregateAddExpr();
          }
          compileAST(newAst, true);
        };

        const checkingASTforMatch = (astHaving: Expr | null, name: string): boolean => {
          if (astHaving === null) {
            return false;
          } else if (isColumnRef(astHaving)) {
            if (astHaving.value === name) {
              return true;
            }
          } else if (isFunCall(astHaving)) {
            return astHaving.args.some(arg => {
              return checkingASTforMatch(arg, name);
            });
          }
          return false;
        };

        const onRemove = (name: string) => {
          if (hasAggregates(this.props.ast.foldEither(a => a), this.props.scope) && checkingASTforMatch(this.props.ast.foldEither(a => a).having, name)) {
              this.setState({ showModalIndex: some(index) });
          } else {
            deleteAggregate();
          }
        };

        const deleteAggregate = () => {
          const editedAst = onRemoveAggregate(ast, selectedExpressions, index, columns);
          compileAST(editedAst, true);
        };

        const showModal = this.state.showModalIndex.match({
          some: (i) => i === index,
          none: () => false,
        });

        const eexpr = selectedExpression.mapBoth(
          ({ untyped: { expr: untyped }, typed: { expr: typed }}) => ({ untyped, typed }),
          ({ expr }) => ({ expr })
        );

        const unAnalyzedName = selectedExpression.fold(
          e => _.get(e.untyped, 'name.name'),
          e => e.hasAlias ? some(e.schemaEntry.name) : none
        );

        return (
          <VisualAggregate
            ast={ast}
            columns={columns}
            parameters={parameters}
            eexpr={eexpr}
            key={index}
            analyzedName={selectedExpression.fold(e => e.typed.name, e => e.schemaEntry.name)}
            unAnalyzedName={unAnalyzedName}
            onUpdate={onUpdate}
            onRemove={onRemove}
            showModal={showModal}
            scope={scope}
            projectionInfo={projectionInfo}
            querySucceeded={querySucceeded}
            dismiss={this.hideModal}
            deleteAggregate={deleteAggregate}/>
        );
      };

      const aggregates = (() => {
        if (usingNewAnalysisEndpoint()) {
          return selectedExpressions.right
            .map((selectedExpression, index) => ({ selectedExpression, index }))
            .filter(({ selectedExpression }) => containsAggregate(scope, selectedExpression.expr))
            .map(({ selectedExpression, index }) => ({ selectedExpression: buildRight(selectedExpression), index }));
        } else {
          return selectedExpressions.left
            .map((selectedExpression, index) => ({ selectedExpression, index }))
            .filter(({ selectedExpression }) => containsAggregate(scope, selectedExpression.untyped.expr))
            .map(({ selectedExpression, index }) => ({ selectedExpression: buildLeft(selectedExpression), index }));
        }
      })().map(renderExpression);

      if (aggregates.length) {
        return aggregates;
      }
    }).orNull;

    return (
      <div className="vee-expr-container vee-gray-bg vee-group-aggregate vee-aggregate-by">
        <SubtitleWithHelper className="aggregate-label" title={t('aggregate_by')} help={help} />
        {items}
        {this.state.showAggregateAddExpr && (
          <AggregateAddExpr
            columns={columns}
            parameters={parameters}
            scope={scope}
            compileAST={compileAST}
            ast={ast}
            projectionInfo={projectionInfo}
            onHideAggregateAddExpr={this.onHideAggregateAddExpr} />
        )}
        <ForgeButton className="add-more">
          <button type="button" onClick={this.onShowAggregateAddExpr} data-testid="add-aggregate-expression">
          <ForgeIcon name="add" />
            {t('add')}
          </button>
        </ForgeButton>
      </div>
    );
  }
}
