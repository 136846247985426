import React from 'react';
import { connect } from 'react-redux';
import { TypedSelect, UnAnalyzedAst } from 'common/types/soql';
import { AppState } from '../redux/store';
import { getUnAnalyzedAst, lastInChain } from '../lib/selectors';
import { hasAnyFilter } from '../lib/clear-all-helpers';
import ClearExpr from './ClearExpr';
import { Tab } from 'common/explore_grid/types';
import { Either } from 'common/either';

interface ClearAllFiltersProps {
  clearEnabled: boolean;
}

function ClearAllFilters({ clearEnabled }: ClearAllFiltersProps) {
  const getClearedAST = (astEither: Either<UnAnalyzedAst, TypedSelect>): Either<UnAnalyzedAst, TypedSelect> => astEither.mapBoth(
    ast => ({
      ...ast,
      having: null,
      where: null
    }),
    ast => ({
      ...ast,
      having: null,
      where: null
    })
  );

  return (<ClearExpr
    clearEnabled={clearEnabled}
    tab={Tab.Filter}
    getClearedAST={getClearedAST} />);
}

const mapStateToProps = (state: AppState): ClearAllFiltersProps => {
  return {
    clearEnabled: hasAnyFilter(getUnAnalyzedAst(state.query).map(lastInChain))
  };
};

export default connect(mapStateToProps)(ClearAllFilters);
