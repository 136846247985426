import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { browserHistory, Route, Router } from 'react-router';
import { Store } from 'redux';
import airbrake from 'common/airbrake';
import { addLocation, locationChanged, Dispatcher } from 'common/explore_grid/redux/actions';
import create, { AppState, LocationParams, ExternalVQEData, VQEColumn } from 'common/explore_grid/redux/store';
import App from 'common/explore_grid/components/App';
import { option, some, none } from 'ts-option';
import _ from 'lodash';
import { viewColumnToVQEColumn } from 'common/explore_grid/lib/selectors';
import { Tab } from 'common/explore_grid/types';

interface AppProps {
  store: Store<AppState> & { dispatch: Dispatcher }; // ARGhghghghghghgh
  history: any; // aaaaaaaaaaaaaagh
}

const Main = ({ store, history }: AppProps) => {
  // wow react-router makes me really sad
  const dispatchLocation = ({ params }: any, preserveCompiledQuery = false) => {
    const locationParams: LocationParams = {
      queryString: option(params.queryString),
      tab: params.tab || Tab.Filter,
      subTab: option(params.subTab)
    };
    if (preserveCompiledQuery) {
      // When switching between subtabs, we don't want to lose the compiled query,
      // so we just update the location info in the store
      store.dispatch(locationChanged(locationParams));
    } else {
      // For any other navigation, we recompile in addition to updating location info
      store.dispatch(addLocation(locationParams));
    }
  };

  const onRouteEnterDefault = (params: any) => dispatchLocation(params);
  const onRouteEnterSubTab = (params: any) => dispatchLocation(params, true);

  // if you change the routing structure here, also change the routes in routes.rb
  return (
    <Provider store={store}>
      <Router history={history}>
        <Route onEnter={onRouteEnterDefault} path="/(:locale/):datasetOrCategory/:name/:fourfour/explore" component={App}>
          <Route onEnter={onRouteEnterDefault} path="query/:queryString" component={App}>
            <Route onEnter={onRouteEnterDefault} path="page/:tab" component={App}>
              <Route onEnter={onRouteEnterSubTab} path=":subTab" component={App}/>
            </Route>
          </Route>
        </Route>
      </Router>
    </Provider>
  );
};

airbrake.init(_.get(window, 'serverConfig.airbrakeProjectId'), _.get(window, 'serverConfig.airbrakeKey'));

const onUrlChanged = (newUrl: string) => {
  browserHistory.push(newUrl);
};

const externalDataProvider = (): ExternalVQEData => {
  const { view, possibleMainParent, canReadFromAllParents, joinsEnabled, clientContext } = window.initialState!;
  const editing = view!.publicationStage === 'unpublished';
  const parentView = (possibleMainParent === undefined || possibleMainParent.id === view!.id) ? none : some(possibleMainParent!);
  const fourfourToQuery = editing && parentView.isDefined ? parentView.get.id : view!.id;
  const queryString = editing && view!.queryString ? some(view!.queryString) : none;
  // these don't correspond to the actual selected columns of the query, but we will set the appState's columns after runQuery, which will happen on page load
  // so it's sort of a best guess until then.
  const columns: VQEColumn[] = viewColumnToVQEColumn(view!.columns);

  return {
    view: view!, columns, fourfourToQuery, editing, canReadFromAllParents: !!canReadFromAllParents, parentView, queryString, joinsEnabled: !!joinsEnabled, clientContext: clientContext!
  };
};

window.addEventListener('load', function() {
  ReactDOM.render(
    <Main history={browserHistory} store={create(onUrlChanged, externalDataProvider, {})}/>,
    document.querySelector('[data-react-component="ExploreGrid"]')
  );
});
