import cx from 'classnames';
import SearchInput from 'common/components/SearchInput';
import DragDropContainer, { DragDropContainerType, DragDropElementWrapper } from 'common/components/DragDropContainer';
import ThreeStateCheckbox, { INDETERMINATE, ThreeStateValue } from 'common/components/ThreeStateCheckbox';
import I18n from 'common/i18n';
import { QueryCompilationResult, CompilationStatus, ViewContext } from 'common/types/compiler';
import { AnalyzedSelectedExpression, ColumnRef, isColumnRef, TypedSoQLColumnRef, TypedOrderBy, UnAnalyzedSelectedExpression, NoPosition, isExpressionEqualIgnoringPosition } from 'common/types/soql';
import { scrollToPosition } from '../lib/scroll-helpers';
import {
  analysisSuccess,
  compilationSuccess,
  querySuccess,
  getSourceColumnFromSelection, getColumns, getColumnsNA, getCompilationProjectionInfo,
  getEditableSelectedExpressionsFromQuery,
  getLastUnAnalyzedAst, getRightmostLeafFromAnalysis,
  getUnAnalyzedAst,
  lastInChain, viewContextFromQuery, getColumnForMetadataRepresentation, getColumnForMetadataRepresentationNA
} from '../lib/selectors';
import { usingNewAnalysisEndpoint } from '../lib/feature-flag-helpers';
import { hasGroupOrAggregate, isSelectedColumn, selectionWithProvenanceFromQueryAnalysis } from '../lib/soql-helpers';
import { AppState, Query, VQEColumn } from '../redux/store';
import { ColumnUpdated, Dispatcher, storeUndoDispatchable } from '../redux/actions';
import _ from 'lodash';
import React from 'react';
import { DragDropContext, DragDropContextConsumer } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
import { connect } from 'react-redux';
import { none, option, Option, some } from 'ts-option';
import '../styles/visual-column-manager.scss';
import { Tab } from 'common/explore_grid/types';
import getColumnRowFragment from './VisualColumnManager/ColumnRowFragment';
import { isColumnRefIdentifier, isSelectionIdentifier, keyForProjectableTerm, ProjectableTerm, ProjectableTermIdentifier, astFromProjectableTerms } from './VisualColumnManager/common';
import { applyFilterPerTerm, filterByColumnName, filterByView } from './VisualColumnManager/filterBehaviors';
import { addOrReplaceOrderBy, clearOrderBy, reorderOrderBy } from './VisualColumnManager/sortBehaviors';
import { ToggleHandlers } from './VisualColumnManager/toggleBehaviors';
import ViewSelectionBar from './VisualColumnManager/ViewSelectionBar';
import * as VisualContainer from './visualContainer';
import WithHandlingOfNonVisualStates from './visualNodes/WithHandlingOfNonVisualStates';
import { RemoteStatusInfo, selectors as SelectRemoteStatus } from '../redux/statuses';
import UnappliedChangesModalWrapper, {
  buildNavigationOptions, NavigateWithUnappliedChanges, Reason
} from './UnappliedChangesModalWrapper';


const t = (k: string) => I18n.t(k, { scope: 'shared.explore_grid.visual_column_manager' });
const header = (k: string) => I18n.t(k, { scope: 'shared.explore_grid.visual_column_manager.headers' });
interface CurrentFilter {
  columnName: string;
  view?: keyof ViewContext;
}
interface State {
  filtering: Option<CurrentFilter>;
  scrollPosition: Option<number>;
  alertDismissed: boolean;
  projDisplayOrder: Option<ProjectableTermIdentifier[]>;
  unappliedChangesModalOpen: boolean;
  onApplyCallback: () => void;
  onDiscardCallback: () => void;
}

interface DispatchProps  {
  columnUpdated: (updatedColumn?: VQEColumn) => void;
}

export type CombinedProps = VisualContainer.VisualContainerProps & DispatchProps & {
  lastClickedApply: Option<Date>;
  modalTargetWindow: ReturnType<typeof window.open>;
  remoteStatusInfo: Option<RemoteStatusInfo>;
  contextualEventHandlers: Partial<AppState['contextualEventHandlers']>;
  stateColumns: VQEColumn[];
};

type ExcludedColumnInfo = {
  analyzedCol: AnalyzedSelectedExpression;
  unAnalyzedCol: UnAnalyzedSelectedExpression;
  originalIndex: number;
  sortData: {
    orderBy: Option<TypedOrderBy>;
    sortIndex: number;
  }
};

class VisualColumnManager extends React.Component<CombinedProps, State> {
  state = {
    filtering: none,
    scrollPosition: none,
    alertDismissed: false,
    projDisplayOrder: none,
    unappliedChangesModalOpen: false,
    onApplyCallback: _.noop,
    onDiscardCallback: _.noop
  } as State;

  getViewContexts = () => viewContextFromQuery(this.props.query);
  getViewColumnColumnRefs = () => getColumns(this.props.query);
  getProjection = () => getCompilationProjectionInfo(this.props.query);

  filterByColumnName = filterByColumnName;
  filterByView = filterByView;
  applyFilterPerTerm = applyFilterPerTerm;

  toggleColumnInclusion = ToggleHandlers.toggleColumnInclusion;
  toggleColumnInclusionUsingOldAnalyzer = ToggleHandlers.toggleColumnInclusionUsingOldAnalyzer;
  toggleColumnInclusionUsingNewAnalyzer = ToggleHandlers.toggleColumnInclusionUsingNewAnalyzer;
  toggleAllColumns = ToggleHandlers.toggleAllColumns;
  toggleAllColumnsUsingOldAnalyzer = ToggleHandlers.toggleAllColumnsUsingOldAnalyzer;
  toggleAllColumnsUsingNewAnalyzer = ToggleHandlers.toggleAllColumnsUsingNewAnalyzer;

  addOrReplaceOrderBy = addOrReplaceOrderBy;
  clearOrderBy = clearOrderBy;
  reorderOrderBy = reorderOrderBy;

  getSnapshotBeforeUpdate(): Option<number> {
    // Grab the scroll position we're at before we update.
    const element = document.querySelector('.scroll-container > div');
    if (element) {
      return some(element.getBoundingClientRect().top);
    }
    return none;
  }

  componentDidMount() {
    this.initializeProjDisplayOrder();
  }

  componentDidUpdate(prevProps: CombinedProps, prevState: State, snapshot: Option<number>) {
    this.props.query.compilationResult.map((result: QueryCompilationResult) => {
      if (result.type === CompilationStatus.Started && !this.state.scrollPosition.isDefined && snapshot.isDefined) {
        // We show a compiling message after an edit, which is what loses the scroll position.
        // So we save the scroll position we had just before compilation to state.
        this.setState({ scrollPosition: snapshot });
      } else if (result.type === CompilationStatus.Succeeded && this.state.scrollPosition.isDefined) {
        // Once compilation succeeds and we're showing the VEE again, scroll to the saved position.
        scrollToPosition('.scroll-container > div', this.state.scrollPosition.getOrElseValue(0));
        this.setState({ scrollPosition: none });
      }
    });

    // resetting the display order when user clicks 'apply'
    if (prevProps.lastClickedApply !== this.props.lastClickedApply) this.setState({projDisplayOrder: none});
    this.initializeProjDisplayOrder();
  }

  initializeProjDisplayOrder = () => {
    if (usingNewAnalysisEndpoint()) {
      this.initializeProjDisplayOrderUsingNewAnalyzer();
    } else {
      this.initializeProjDisplayOrderUsingOldAnalyzer();
    }
  };
  initializeProjDisplayOrderUsingNewAnalyzer = () => {
    analysisSuccess(this.props.query.analysisResult).map(success => {
      if (this.state.projDisplayOrder.isEmpty) {
        const orderedProjectionIds : ProjectableTermIdentifier[] = this.getColumns().sort((a, b) => {
          return this.sortProjectionRows(a, b);
        }).map(col => {
          if (col.ref.nonEmpty) {
            return {
              type: 'column_ref_identifier',
              qualifier: col.ref.get.qualifier,
              value: col.ref.get.value
            };
          }
          return {
            type: 'selection_identifier',
            // col.expr is guaranteed if col.ref is empty
            selectionName: col.expr.get.name
          };
        });

        if (orderedProjectionIds.length !== 0) this.setState({projDisplayOrder: some(orderedProjectionIds)});
      }
    });
  };
  initializeProjDisplayOrderUsingOldAnalyzer = () => {
    compilationSuccess(this.props.query.compilationResult).map(success => {
      if (this.state.projDisplayOrder.isEmpty) {
        const orderedProjectionIds : ProjectableTermIdentifier[] = this.getColumns().sort((a, b) => {
          return this.sortProjectionRows(a, b);
        }).map(col => {
          if (col.ref.nonEmpty) {
            return {
              type: 'column_ref_identifier',
              qualifier: col.ref.get.qualifier,
              value: col.ref.get.value
            };
          }
          return {
            type: 'selection_identifier',
            // col.expr is guaranteed if col.ref is empty
            selectionName: col.expr.get.name
          };
        });

        if (orderedProjectionIds.length !== 0) this.setState({projDisplayOrder: some(orderedProjectionIds)});
      }
    });
  };

  isGrouped(): boolean {
    const { query, scope } = this.props;
    return hasGroupOrAggregate(getLastUnAnalyzedAst(query), scope);
  }

  sortProjectionRows = (a: ProjectableTerm, b: ProjectableTerm): number => {
    return a.displayIndex - b.displayIndex;
  };

  getColumns = (): ProjectableTerm[] => {
    if (usingNewAnalysisEndpoint()) {
      return this.getColumnsUsingNewAnalyzer();
    } else {
      return this.getColumnsUsingOldAnalyzer();
    }
  };

  getColumnsUsingNewAnalyzer = (): ProjectableTerm[] => {
    const { view } = this.props;
    const vccrs = getColumnsNA(this.props.query);
    const projection = analysisSuccess(this.props.query.analysisResult).map(analysis => {
      // This ignores star selections as not-supported for now.
      const { order_bys: orderBys } = lastInChain(analysis.ast);
      return selectionWithProvenanceFromQueryAnalysis(analysis)
        .map((selectionItem, projectionIndex) => {
          const schemaEntry = selectionItem.schemaEntry;
          const typedRef = selectionItem.expr;

          selectionItem.provenance.forEach(({ column }) => {
            // Exclude projected columns from the vccrs list so that unProjectedViewColumns works.
            _.remove(vccrs, match => _.isEqual(column, match.column));
          });

          const analyzedSelectedExpr: AnalyzedSelectedExpression = {
            expr: typedRef,
            name: schemaEntry.name
          };
          const unAnalyzedExpr: UnAnalyzedSelectedExpression = {
            expr: selectionItem.expr,
            name: { name: schemaEntry.name, position: NoPosition }
          };
          const metadataColumn = getColumnForMetadataRepresentationNA(
            schemaEntry, this.props.stateColumns, analysis
          );

          let sortIndex = -1;
          const orderBy = option(orderBys.find((ob, index) => {
            if (isExpressionEqualIgnoringPosition(ob.expr, typedRef)) {
              sortIndex = index;
              return true;
            }
          }));

          // TODO: This is only a thing to make ProjectableTerm, which expected an untyped(?) ColumnRef, happy.
          // The eventual goal should be to change ProjectableTerm to use SelectionItem itself, but that is
          // a future ambition, not in scope for this current task.
          const columnRef: Option<TypedSoQLColumnRef> = (() => {
            if (isSelectedColumn(selectionItem)) {
              return some(selectionItem.expr as TypedSoQLColumnRef);
            } else {
              return none;
            }
          })();

          const displayIndex = isSelectedColumn(selectionItem)
            ? this.getDisplayIndex(none, some(selectionItem.expr), projectionIndex)
            : this.getDisplayIndex(some(selectionItem.schemaEntry.name), none, projectionIndex);

          return {
            expr: some(analyzedSelectedExpr),
            unAnalyzedExpr: some(unAnalyzedExpr),
            viewColumn: option(selectionItem.provenance.map(p => p.column).getOrElseValue(null)),
            metadataColumn,
            typedRef: columnRef,
            ref: columnRef,
            view: selectionItem.provenance.map(p => p.view),
            displayIndex,
            projectionIndex,
            sortData: { orderBy, sortIndex }
          };
        });
    }).getOrElseValue([]);

    // If data is grouped, we don't want to show the ungrouped columns, so we
    // return here without adding the excluded columns to the list.
    if (this.isGrouped()) return projection;

    const unProjectedViewColumns = vccrs
      .filter(viewColumnColumnRef => !(viewColumnColumnRef.column.flags || []).includes('hidden'))
      .sort((a,b):number => {
        const aViewName = some(a.view).match({ some: v => v.name, none: () => '' });
        const bViewName = some(b.view).match({ some: v => v.name, none: () => '' });
        return aViewName < bViewName ? -1 : 1;
      })
      .map((viewColumnColumnRef, index) => ({
        expr: none as ProjectableTerm['expr'],
        unAnalyzedExpr: none as ProjectableTerm['unAnalyzedExpr'],
        viewColumn: some(viewColumnColumnRef.column),
        metadataColumn: none,
        typedRef: some(viewColumnColumnRef.typedRef),
        ref: some(viewColumnColumnRef.typedRef),
        view: some(viewColumnColumnRef.view),
        projectionIndex: -1,
        displayIndex: this.getDisplayIndex(none, some(viewColumnColumnRef.ref), projection.length + index),
        sortData: { orderBy: none, sortIndex: -1 },
      }));

    const excludedColInfos = getAllExcludedCalculatedColumnsNA(this.props.query);
    const removedColumnToBeReadded = excludedColInfos.map(excludedColInfo => {
      return {
        expr: some(excludedColInfo.analyzedCol),
        unAnalyzedExpr: some(excludedColInfo.unAnalyzedCol),
        viewColumn: none,
        metadataColumn: none,
        typedRef: none,
        ref: none,
        view: option(view),
        displayIndex: this.getDisplayIndex(some(excludedColInfo.analyzedCol.name), none, excludedColInfo.originalIndex),
        projectionIndex: -1,
        sortData: excludedColInfo.sortData
      };
    });

    return projection.concat(removedColumnToBeReadded).concat(unProjectedViewColumns);
  };

  getColumnsUsingOldAnalyzer = (): ProjectableTerm[] => {
    const { view } = this.props;
    const compSuccess = compilationSuccess(this.props.query.compilationResult);
    const vccrs = this.getViewColumnColumnRefs();
    const projection = compSuccess.map(success => {
      const proj = lastInChain(success.analyzed).selection;
      const projUnanalyzed = lastInChain(success.unanalyzed).selection;
      const orderBys = lastInChain(success.analyzed).order_bys;
      return proj.map((selection, projectionIndex) => {
        const sourceColumn = getSourceColumnFromSelection(selection, success);
        const metadataColumn = getColumnForMetadataRepresentation(selection, this.props.stateColumns, success);

        const vccr = vccrs.find(eachVccr => sourceColumn.match({
          some: sourceCol => _.isEqual(eachVccr.column, sourceCol), none: () => false
        }));
        // Nothing else should match this.
        _.remove(vccrs, match => _.isEqual(vccr, match));

        let sortIndex = -1;
        const orderBy = option(orderBys.find((ob, index) => {
          if (_.isEqual(ob.expr, selection.expr)) {
            sortIndex = index;
            return true;
          }
        }));

        const displayIndex = (vccr?.ref) ? this.getDisplayIndex(none, some(vccr.ref), projectionIndex) :
          this.getDisplayIndex(some(selection.name), none, projectionIndex);  // if this is a columnRef pass it in instead of selection name.

        return {
          expr: some(selection),
          unAnalyzedExpr: some(projUnanalyzed.exprs[projectionIndex]),
          viewColumn: option(vccr?.column),
          metadataColumn: metadataColumn,
          typedRef: option(vccr?.typedRef),
          ref: option(vccr?.ref),
          view: option(vccr?.view),
          displayIndex: displayIndex,
          projectionIndex,
          sortData: { orderBy, sortIndex }
        };
      });
    }).getOrElseValue([]);

    // If data is grouped, we don't want to show the ungrouped columns, so we
    // return here without adding the excluded columns to the list.
    if (this.isGrouped()) return projection;

    const unProjectedViewColumns = vccrs
      .filter(viewColumnColumnRef => !(viewColumnColumnRef.column.flags || []).includes('hidden'))
      .sort((a,b):number => {
        const aViewName = some(a.view).match({ some: v => v.name, none: () => '' });
        const bViewName = some(b.view).match({ some: v => v.name, none: () => '' });
        return aViewName < bViewName ? -1 : 1;
      })
      .map((viewColumnColumnRef, index) => ({
        expr: none as ProjectableTerm['expr'],
        unAnalyzedExpr: none as ProjectableTerm['unAnalyzedExpr'],
        viewColumn: some(viewColumnColumnRef.column),
        metadataColumn: none,
        typedRef: some(viewColumnColumnRef.typedRef),
        ref: some(viewColumnColumnRef.ref),
        view: some(viewColumnColumnRef.view),
        projectionIndex: -1,
        displayIndex: this.getDisplayIndex(none, some(viewColumnColumnRef.ref), projection.length + index),
        sortData: { orderBy: none, sortIndex: -1 },
    }));

    const excludedColInfos = getAllExcludedCalculatedColumns(this.props.query);
    const removedColumnToBeReadded = excludedColInfos.map(excludedColInfo => {
      return {
        expr: some(excludedColInfo.analyzedCol),
        unAnalyzedExpr: some(excludedColInfo.unAnalyzedCol),
        viewColumn: none,
        metadataColumn: none,
        typedRef: none,
        ref: none,
        view: option(view),
        displayIndex: this.getDisplayIndex(some(excludedColInfo.analyzedCol.name), none, excludedColInfo.originalIndex),
        projectionIndex: -1,
        sortData: excludedColInfo.sortData
      };
    });

    return projection.concat(removedColumnToBeReadded).concat(unProjectedViewColumns);
  };

  getDisplayIndex = (selectionName: Option<string>, colRef: Option<ColumnRef>, initialIndex: number): number => {
    const projDisplayOrder = this.state.projDisplayOrder;
    return projDisplayOrder.map(projs => {
      return projs.findIndex(proj => {
        if (selectionName.nonEmpty && isSelectionIdentifier(proj) ) return selectionName.get === proj.selectionName;
        if (colRef.nonEmpty && isColumnRefIdentifier(proj)) return colRef.get.qualifier === proj.qualifier && colRef.get.value === proj.value;
        return false;
      });
    }).getOrElseValue(initialIndex);
  };

  includingAllColumns = (columns: ProjectableTerm[]): ThreeStateValue => {
    if (columns.length === 0) {
      return false;
    } else if (_.every(columns, c => c.projectionIndex > -1)) {
      return true;
    } else if (_.some(columns, c => c.projectionIndex > -1)) {
      return INDETERMINATE;
    } else {
      return false;
    }
  };

  dismissAlert = () => {
    this.setState({ alertDismissed: true });
  };

  navigateWithUnappliedChanges: NavigateWithUnappliedChanges = (navigate, partialOptions = {}) => {
    const options = buildNavigationOptions(partialOptions);
    const shouldDiscardChanges = SelectRemoteStatus.discardChangesOnTabChange(this.props.remoteStatusInfo).isDefined;
    const shouldOpenModal = SelectRemoteStatus.applyable(this.props.remoteStatusInfo).isDefined;

    const navigateOnApply = options.navigateOnApply ? navigate : _.noop;
    const navigateOnDiscard = options.navigateOnDiscard ? navigate : _.noop;

    if (shouldDiscardChanges) {
      this.setState({ unappliedChangesModalOpen: false });
      this.props.discardChanges();
      navigate();
    } else if (shouldOpenModal) {
      this.setState({
        unappliedChangesModalOpen: true,
        onDiscardCallback: navigateOnDiscard,
        onApplyCallback: navigateOnApply
      });
    } else {
      navigate();
    }
  };

  onDrop = (reorderedTermIds: ProjectableTermIdentifier[]) => {
    this.setState({projDisplayOrder: some(reorderedTermIds)});
    const columns = this.getColumns();
    if (usingNewAnalysisEndpoint()) {
      getRightmostLeafFromAnalysis(this.props.query).forEach(ast => {
        this.props.compileAST(astFromProjectableTerms(reorderedTermIds, columns, ast), false);
      });
    } else {
      getUnAnalyzedAst(this.props.query).map(lastInChain).forEach(ast => {
        this.props.compileAST(astFromProjectableTerms(reorderedTermIds, columns, ast), false);
      });
    }
  };

  getDragListItems = (): ProjectableTermIdentifier[] => {
    return this.state.projDisplayOrder.getOrElseValue([]);
  };

  getDragListElements = (columns: ProjectableTerm[], showDatasetColumn: boolean, isGrouped: boolean): DragDropElementWrapper[] => {
    const filteredColumns = columns
      .sort(this.sortProjectionRows)
      .filter(term => this.applyFilterPerTerm(term));
    const currentlyFiltering = this.state.filtering.isDefined;
    const lastLeaf = usingNewAnalysisEndpoint()
      ? getRightmostLeafFromAnalysis(this.props.query)
      : getLastUnAnalyzedAst(this.props.query);
    const orderByCount = lastLeaf
      .map(ast => ast.order_bys.length).getOrElseValue(0);

    return filteredColumns.map((projectableTerm, idx) => {
      const rowFragment = getColumnRowFragment({
        idx: idx,
        projectableTerm: projectableTerm,
        showDatasetColumn: showDatasetColumn,
        columnBehaviors: {
          toggleColumn: (...args) => this.toggleColumnInclusion(...args),
          addOrReplaceOrderBy: (...args) => this.addOrReplaceOrderBy(...args),
          clearOrderBy: (...args) => this.clearOrderBy(...args),
          reorderOrderBy: (...args) => this.reorderOrderBy(...args)
        },
        orderByCount: orderByCount,
        showIncludeCheckbox: !isGrouped,
        toEditColumnMetadata: this.props.contextualEventHandlers.editColumnMetadata,
        toFormatColumn: this.props.contextualEventHandlers.formatColumn,
        columnUpdated: this.props.columnUpdated,
        navigateWithUnappliedChanges: (...args) => this.navigateWithUnappliedChanges(...args)
      });

      const columnIsInProjection = projectableTerm.projectionIndex > -1;
      const classNames = {
        'column-row-projected': columnIsInProjection && !currentlyFiltering
      };

      return {
        element: rowFragment,
        className: cx('column-row', classNames),
        id: keyForProjectableTerm(projectableTerm),
        dragAndDroppable: columnIsInProjection && !currentlyFiltering,
        droppable: !(columnIsInProjection && !currentlyFiltering)
      };
    });
  };

  onDiscardChanges = () => {
    this.setState({
      unappliedChangesModalOpen: false,
      onApplyCallback: _.noop,
      onDiscardCallback: _.noop
    });
    this.props.discardChanges();
    this.state.onDiscardCallback();
  };

  onApplyChanges = () => {
    this.setState({
      unappliedChangesModalOpen: false,
      onApplyCallback: _.noop,
      onDiscardCallback: _.noop
    });
    this.props.applyChanges(none);
    this.state.onApplyCallback();
  };

  render() {
    const { modalTargetWindow, query } = this.props;
    const { alertDismissed, unappliedChangesModalOpen } = this.state;
    const columns = this.getColumns();
    const viewContexts = this.getViewContexts().getOrElseValue({} as ViewContext);
    const includingAllColumns = this.includingAllColumns(columns);
    const showDatasetColumn = _.size(viewContexts) > 1; // more than one view context
    const isGrouped = this.isGrouped();
    const dragDropListElements = this.getDragListElements(columns, showDatasetColumn, isGrouped);
    /**
     * When we undock, the "current" window changes. However, because react-dnd doesn't anticipate this, its internal
     * machinery doesn't realize it needs to hook up to the new window. This little piece of hackery notices when our
     * current window has changed and pokes at react-dnd's internal machinery to hook things up correctly again.
     *
     * It is likely fragile and could easily break upon upgrades. I'm really sorry and wish I had a better solution.
     */
    const reSetUpDragDropContextForExternalWindow = (
      <DragDropContextConsumer>
        { ({ dragDropManager: { context, backend }}: any) => {
          if (context.window?.name !== modalTargetWindow?.name) {
            if (modalTargetWindow) {
              backend.teardown();
              context.window = modalTargetWindow;
              backend.setup();
            } else {
              backend.teardown();
              delete context.window;
              backend.setup();
            }
          }
          return null;
        }}
      </DragDropContextConsumer>
    );

    return (
      <div className="grid-datasource-components scroll-container visual-column-manager">
        <WithHandlingOfNonVisualStates remoteStatusInfo={this.props.remoteStatusInfo} query={query}>
          {reSetUpDragDropContextForExternalWindow}

          { unappliedChangesModalOpen && <UnappliedChangesModalWrapper
              onPrimaryAction={() => this.onApplyChanges()}
              onDiscardChanges={() => this.onDiscardChanges()}
              onDismiss={() => this.setState({ unappliedChangesModalOpen: false })}
              isOpen={unappliedChangesModalOpen}
              reason={Reason.TAB} />}

          <ViewSelectionBar
            viewContexts={viewContexts}
            selected={this.state.filtering.map(f => f.view).getOrElseValue(undefined)}
            onChooseContext={(...args) => this.filterByView(...args)}
          />

          <div className="manager-preamble">
            {!isGrouped && <ThreeStateCheckbox
              id="include-all-columns"
              checked={includingAllColumns}
              onChange={() => this.toggleAllColumns()}>
              {t('include_all_columns')}
            </ThreeStateCheckbox>}
            <div id="column-search-input-wrapper">
              <SearchInput
                id={'column-search-input'}
                value={this.state.filtering.map(f => f.columnName).getOrElseValue('')}
                onSearch={_.noop}
                onChange={(str: string) => this.filterByColumnName(str)}
                title={t('column_filter_placeholder')}
              />
            </div>
            {isGrouped && !alertDismissed && <div className="alert info column-grouping-alert">
              <span>{t('alert_text_1')} <a onClick={() => this.props.updateTab(Tab.Aggregate)}>{t('alert_text_2')}</a></span>
              <button onClick={this.dismissAlert} className="btn btn-link" aria-label={t('dismiss_alert')}>
                <span className="socrata-icon-close-2"></span>
              </button>
            </div>}
          </div>

          <form>
            <table>
              <thead>
                <tr>
                  <th className="order-header">{header('order')}</th>
                  { !isGrouped && <th className="include-header">{header('include')}</th> }
                  <th className="column-name-header">{header('column_name')}</th>
                  <th className="field-name-header">{header('field_name')}</th>
                  { showDatasetColumn && <th className="dataset-header">{header('source')}</th>}
                  <th className="sort-header">{header('sort')}</th>
                  <th className="sort-order-header">{header('sort_order')}</th>
                  { this.props.contextualEventHandlers.formatColumn &&
                    <th className="column-formatting">{header('column_formatting')}</th>}
                </tr>
              </thead>
              <DragDropContainer
                childElements={dragDropListElements}
                items={this.getDragListItems()}
                onDrop={this.onDrop}
                type={DragDropContainerType.TABLE}>
              </DragDropContainer>
            </table>
          </form>
        </WithHandlingOfNonVisualStates>
      </div>
    );
  }
}
export { VisualColumnManager };

export function getAllExcludedCalculatedColumns(query: Query): ExcludedColumnInfo[] {
  const { compilationResult } = query;

  // if there's no compilation success, we won't consider any of the calculated columns to be currently selected
  const selectionAndOrderBy = compilationSuccess(compilationResult).map(compSuccess => {
    return {
      selection: lastInChain(compSuccess.analyzed).selection,
      orderBys: lastInChain(compSuccess.analyzed).order_bys,
    };
  }).getOrElseValue({selection: [], orderBys: []});


  const querySelectionOpt = getEditableSelectedExpressionsFromQuery(query);

  if (querySelectionOpt.isEmpty) return [];
  const querySelection = querySelectionOpt.get;
  const compiledSelection = selectionAndOrderBy.selection;

  // if everything is successful
  // find all excludedColumns
  // a column is NEWLY excluded if:
  // 1. its in the querySelection (otherwise it was excluded previously)
  // 2. its not in the compiledSelection (otherwise its not excluded at all)
  // 3. its not a columnRef (otherwise its not a calculated column)
  const excluded = querySelection.filter((eExpr) => {
    const col = eExpr.typed;
    const inCompiled = () => compiledSelection.some((expr: AnalyzedSelectedExpression) => _.isEqual(expr.expr, col.expr));
    return !isColumnRef(col.expr) && !inCompiled();
  });

  return excluded.map((eExpr) => {
    let sortIndex = -1;
    const orderBy = option(selectionAndOrderBy.orderBys.find((ob, index) => {
      if (_.isEqual(ob.expr, eExpr.typed)) {
        sortIndex = index;
        return true;
      }
    }));
    return {
      analyzedCol: eExpr.typed,
      unAnalyzedCol: eExpr.untyped,
      originalIndex: querySelection.indexOf(eExpr),
      sortData: { orderBy, sortIndex }
    };
  });
}
export function getAllExcludedCalculatedColumnsNA(query: Query): ExcludedColumnInfo[] {
  const { analysisResult, queryResult } = query;

  // if there's no compilation success, we won't consider any of the calculated columns to be currently selected
  const selectionAndOrderBy = analysisSuccess(analysisResult).map(success => {
    const { selection: { exprs: selection }, order_bys: orderBys } = lastInChain(success.ast);
    return { selection, orderBys };
  }).getOrElseValue({selection: [], orderBys: []});


  const querySelectionOpt = querySuccess(queryResult).flatMap(q => q.analyzed.map(selectionWithProvenanceFromQueryAnalysis));

  if (querySelectionOpt.isEmpty) return [];
  const querySelection = querySelectionOpt.get;
  const compiledSelection = selectionAndOrderBy.selection;

  // if everything is successful
  // find all excludedColumns
  // a column is NEWLY excluded if:
  // 1. its in the querySelection (otherwise it was excluded previously)
  // 2. its not in the compiledSelection (otherwise its not excluded at all)
  // 3. its not a columnRef (otherwise its not a calculated column)
  const excluded = querySelection.filter((selectionItem) => {
    const inCompiled = () => compiledSelection.some((expr) => isExpressionEqualIgnoringPosition(expr.expr, selectionItem.expr));
    return !isColumnRef(selectionItem.expr) && !inCompiled();
  });

  return excluded.map((selectionItem) => {
    let sortIndex = -1;
    const orderBy = option(selectionAndOrderBy.orderBys.find((ob, index) => {
      if (_.isEqual(ob.expr, selectionItem.expr)) {
        sortIndex = index;
        return true;
      }
    }));
    return {
      analyzedCol: {
        name: selectionItem.schemaEntry.name,
        expr: selectionItem.expr
      },
      unAnalyzedCol: {
        name: { name: selectionItem.schemaEntry.name, position: NoPosition },
        expr: selectionItem.expr
      },
      originalIndex: querySelection.indexOf(selectionItem),
      sortData: { orderBy, sortIndex }
    };
  });
}

const mapStateToProps = (state: AppState, props: VisualContainer.ExternalProps) => {
  return {
    ...VisualContainer.mapStateToProps(state, props),
    lastClickedApply: state.applyInfo.lastClickedApply,
    modalTargetWindow: state.modalTargetWindow,
    remoteStatusInfo: state.remoteStatusInfo,
    contextualEventHandlers: state.contextualEventHandlers,
    stateColumns: state.columns
  };
};

export const mapDispatchToProps = (dispatch: Dispatcher): VisualContainer.VisualContainerDispatchProps & DispatchProps => {
  return {
    ...VisualContainer.mapDispatchToProps(dispatch),
    columnUpdated: (updatedColumn?: VQEColumn) => {
      if (updatedColumn) dispatch(ColumnUpdated(updatedColumn));
      dispatch(storeUndoDispatchable());
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  VisualContainer.mergeProps
)(DragDropContext(HTML5Backend)(VisualColumnManager)); // eslint-disable-line new-cap
