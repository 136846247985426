
import Button, { VARIANTS } from 'common/components/Button';
import I18n from 'common/i18n';
import React from 'react';
import { Modal, ModalHeader, ModalFooter, ModalContent } from 'common/components/Modal';

const t = (k: string, options: { [key: string]: any } = {}) => I18n.t(k, { scope: 'shared.explore_grid.save_as_modal', ...options });

interface AggregateModalProps {
  onDismiss: () => void;
  deleteAggregate: () => void;
  apiFieldName: string;
}

const mystyles = {
    display:'inline',
    marginRight:'10px'
} as React.CSSProperties;

const AggregateModal = ({
  onDismiss,
  deleteAggregate,
  apiFieldName
}: AggregateModalProps) => {
  return (
    <Modal
      onDismiss={onDismiss}
      dismissOnOutsideClick={false}
      className="delete-aggregate-modal"
    >
      <ModalHeader showCloseButton onDismiss={onDismiss} />
      <ModalContent>
        <h1>{t('delete')}</h1>
        <div>
          {t('description')}
        </div>
      </ModalContent>
      <ModalFooter>
        <div>
          <div style={mystyles}><Button variant={VARIANTS.DEFAULT} onClick={onDismiss}>{t('cancel')}</Button></div>
          <Button variant={VARIANTS.ERROR} onClick={deleteAggregate}>{t('delete')}</Button>
        </div>
      </ModalFooter>
    </Modal>
  );
};

export default AggregateModal;
