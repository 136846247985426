import ExportModal, { StateProps } from 'common/components/ExportModal';
import { connect } from 'react-redux';
import { AppState } from '../../redux/store';
import { QueryStatus } from 'common/explore_grid/types';
import { QueryCompilationSucceeded} from 'common/types/compiler';
import { none, some } from 'ts-option';

const mapStateToProps = (state: AppState): StateProps => {
  const queryResult = state.query.queryResult.flatMap<QueryCompilationSucceeded>((qr) => {
    return qr.type == QueryStatus.QUERY_SUCCESS ? some(qr.compiled) : none;
  });
  return {
    query: queryResult,
    view: state.view,
    clientContextVariables: state.clientContextInfo.variables
  };
};

export default connect(mapStateToProps)(ExportModal);
