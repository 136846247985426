import React, { useRef } from 'react';

export interface DragWidthHandleProps {
  onDragStart: (pageX: number) => void;
  onDragEnd: () => void;
  onDragMovement: (pageX: number) => void;
  name: string;
}

const DragWidthHandle: React.FunctionComponent<DragWidthHandleProps> = ({
  onDragStart,
  onDragEnd,
  onDragMovement,
  name
}) => {
  const dragRef = useRef(null);

  const onMouseDown = (event: React.MouseEvent<HTMLDivElement>) => {
    event.preventDefault();
    if (event.button === 2) return;
    onDragStart(event.pageX);
    document.addEventListener('mousemove', onDrag);
    document.addEventListener('mouseup', onMouseUp);
  };

  const onDrag = (event: MouseEvent) => {
    onDragMovement(event.pageX);
  };

  const onMouseUp = () => {
    onDragEnd();
    document.removeEventListener('mousemove', onDrag);
    document.removeEventListener('mouseup', onMouseUp);
  };

  return (
    <div
      data-testid={`${name}-drag-handle`}
      ref={dragRef}
      draggable="true"
      className="drag-handle"
      onMouseDown={onMouseDown}
    />
  );
};

export default DragWidthHandle;
