import React from 'react';
import { fetchTranslation } from 'common/locale';
import Button, { VARIANTS } from 'common/components/Button';
import { Modal, ModalHeader, ModalContent, ModalFooter } from 'common/components/AccessibleModal';

const t = (k: string) => fetchTranslation(k, 'shared.explore_grid.remove_group_modal');

interface Props {
  isOpen: boolean;
  onDeleteGroup: () => void;
  onCancel: () => void;
}

export default function RemoveGroupModal(props: Props) {
  const { isOpen, onDeleteGroup, onCancel } = props;

  return (
    <Modal onRequestClose={onCancel} isOpen={isOpen} id="unapplied-changes-modal">
      <ModalHeader onDismiss={onCancel}>
        <h2>{t('title')}</h2>
      </ModalHeader>
      <ModalContent>
        <div>{t('body')}</div>
      </ModalContent>
      <ModalFooter>
        <Button
          className="cancel-remove"
          variant={VARIANTS.ERROR}
          onClick={onCancel}>
          {t('cancel')}
        </Button>
        <Button
          className="delete-group"
          variant={VARIANTS.PRIMARY}
          onClick={onDeleteGroup}>
          {t('delete_group')}
        </Button>
      </ModalFooter>
    </Modal>
  );
}
