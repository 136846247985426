import React, { Fragment } from 'react';
import { Option } from 'ts-option';
import CopyToClipboard from 'react-copy-to-clipboard';
import SocrataIcon, { IconName } from 'common/components/SocrataIcon';
import I18n from 'common/i18n';
import Flyout from 'common/components/Flyout';

import { Tab } from 'common/explore_grid/types';
import {
  RemoteStatus, RemoteStatusInfo,
  selectors as SelectRemoteStatus,
  translateRemoteStatus
} from '../redux/statuses';
import { ForgeButton } from '@tylertech/forge-react';

const t = (k: string) => I18n.t(k, { scope: 'shared.explore_grid.grid_datasource.footer' });

export interface DatasourceFooterProps {
  currentTab: Tab;
  remoteStatusInfo: Option<RemoteStatusInfo>;
  onApplyQuery: () => void;
}

export class DatasourceFooter extends React.Component<DatasourceFooterProps> {
  renderBanner() {
    const { currentTab, remoteStatusInfo } = this.props;
    const classNames = [] as string[];
    const defaultBanner = <Fragment>{''}</Fragment>;
    const supportEmail = 'socrata-support@tylertech.com';

    const banner = remoteStatusInfo.map(rsi => {
      if (rsi.type === RemoteStatus.CannotCompileQuery || rsi.type === RemoteStatus.CannotRunQuery) {
        let copyRequestId = null;
        classNames.push(rsi.reason === 'no_columns' ? 'apply-warning' : 'apply-danger');
        if (rsi.type === RemoteStatus.CannotRunQuery) {
          copyRequestId = rsi.requestId.map(requestId => (
            <CopyToClipboard text={requestId} key="quiet-eslint">
              <span className="copy-request-id">
                <SocrataIcon name={IconName.Link} />
                {t('copy_request_id')}
              </span>
            </CopyToClipboard>)
          ).getOrElseValue(<Fragment />);
        }
        return <Fragment>
          <SocrataIcon name={IconName.Warning} />
          <span dangerouslySetInnerHTML={{ __html: translateRemoteStatus(rsi.reason, {
            supportEmail: `<a href="mailto:${supportEmail}">${supportEmail}</a>`
          })}} />
          {copyRequestId}
        </Fragment>;
      }

      if (rsi.type === RemoteStatus.CollocatingCompiledQuery) {
        return translateRemoteStatus('collocation_in_progress');
      }

      if (rsi.type === RemoteStatus.QueryRanSuccessfully) {
        const onSuccessTab = rsi.successTab.map(tab => tab === currentTab).getOrElseValue(false);
        if (onSuccessTab) {
          classNames.push('apply-success');
          return <Fragment>{t('apply_succeeded')}</Fragment>;
        }
      }
    }).getOrElseValue(defaultBanner);

    return <div className={classNames.join(' ')}>{banner}</div>;
  }

  renderButton() {
    const { remoteStatusInfo, onApplyQuery } = this.props;
    const applyEnabled = SelectRemoteStatus.applyable(remoteStatusInfo).isDefined;

    const defaultBtn = (
      <ForgeButton type="raised">
        <button
          className='apply-button'
          id={'apply-button'}
          onClick={onApplyQuery}
          disabled={!applyEnabled}>
          {t('apply')}
        </button>
      </ForgeButton>
    );

    return remoteStatusInfo.map(rsi => rsi.type === RemoteStatus.CannotCompileQuery && rsi.reason === 'no_columns' ? (
      <Flyout text={t('no_columns')} top>
        {defaultBtn}
      </Flyout>
    ) : defaultBtn).getOrElseValue(defaultBtn);
  }

  render() {
    return (
      <div className="datasource-footer" data-testid="datasource-footer">
        {this.renderButton()}
        {this.renderBanner()}
      </div>
    );
  }
}

export default DatasourceFooter;
