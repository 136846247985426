import React from 'react';
import { connect } from 'react-redux';
import { UnAnalyzedAst, UnAnalyzedSelectedExpression, AnalyzedSelectedExpression, NamedExpr, TypedSelect } from 'common/types/soql';
import { Either } from 'common/either';
import { AppState } from '../redux/store';
import { EditableExpression, EditableExpressionNA, Tab } from 'common/explore_grid/types';
import { Option } from 'ts-option';
import { buildSelection, buildSelectionNA, dropOrderBys, dropOrderBysNA } from './VisualGroupAggregateEditor';
import { getEditableSelectedExpressions, getColumns, getUnAnalyzedAst, lastInChain, ViewColumnColumnRef } from '../lib/selectors';
import { hasGroupOrAggregate, SelectionItem } from '../lib/soql-helpers';
import ClearExpr from './ClearExpr';

interface ClearAllGroupsProps {
  columns: ViewColumnColumnRef[];
  clearEnabled: boolean;
  selectedExprs: Option<Either<EditableExpression<UnAnalyzedSelectedExpression, AnalyzedSelectedExpression>[], SelectionItem[]>>;
}

function ClearAllGroups({ clearEnabled, columns, selectedExprs }: ClearAllGroupsProps) {
  const getClearedAST = (astEither: Either<UnAnalyzedAst, TypedSelect>): Either<UnAnalyzedAst, TypedSelect> => astEither.mapBoth(
    ast => ({
      ...ast,
      group_bys: [],
      having: null,
      selection: buildSelection([], [], columns),
      order_bys: selectedExprs.map((se) => dropOrderBys(se.left, [])).getOrElseValue(ast.order_bys)
    }),
    ast => ({
      ...ast,
      group_bys: [],
      having: null,
      selection: buildSelectionNA([], [], columns),
      order_bys: selectedExprs.map((se) => dropOrderBysNA(se.right, [])).getOrElseValue(ast.order_bys)
    }),
  );
  return (<ClearExpr
    clearEnabled={clearEnabled}
    getClearedAST={getClearedAST}
    tab={Tab.Aggregate} />);
}

const mapStateToProps = (state: AppState): ClearAllGroupsProps => {
  const { query, scope } = state;
  const columns = getColumns(query);
  const selectedExprs = getEditableSelectedExpressions(query);
  const clearEnabled = hasGroupOrAggregate(getUnAnalyzedAst(query).map(lastInChain), scope.getOrElseValue([]));

  return {
    clearEnabled,
    columns,
    selectedExprs
  };
};

export default connect(mapStateToProps)(ClearAllGroups);
