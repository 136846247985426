import React, { FunctionComponent, useRef, useState } from 'react';
import classNames from 'classnames';
import { ForgePopup, ForgeIcon } from '@tylertech/forge-react';

interface Props {
  title: string;
  help: string | JSX.Element;
  className?: string;
}

const SubtitleWithHelper: FunctionComponent<Props> = ({ title, help, className }: Props) => {
  const targetRef = useRef<any>();
  const [flyoutOpen, setFlyoutOpen] = useState(false);

  const onMouseEnter = () => {
    setFlyoutOpen(true);
  };

  const onMouseLeave = () => {
    setFlyoutOpen(false);
  };

  return (
    <div>
      <div className={classNames('block-label', 'label-with-helper', 'forge-typography--subtitle1-secondary' , className)}>
        <span className='forge-typography--subtitle1-secondary'>{title}</span>
        <span
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          ref={targetRef}
          className='icon-wrapper'
        >
          <ForgeIcon name='info_outline' className='label-icon' />
        </span>
      </div>

      <ForgePopup
        targetElementRef={targetRef}
        open={flyoutOpen}
        onDismiss={() => {}}
      >
        <div
          style={{ width: '600px', padding: '8px' }}
          className="forge-typography--body2"
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
        >
          {help}
        </div>
      </ForgePopup>
    </div>
  );
};

export default SubtitleWithHelper;
