import React from 'react';
import ClearAllGroups from './ClearAllGroups';
import ClearAllFilters from './ClearAllFilters';
import { Tab } from 'common/explore_grid/types';

interface Props {
  tab: Tab;
}

export default function DatasourceClearAllExpr(props: Props): JSX.Element | null {
  const { tab } = props;
  if (tab === Tab.Aggregate) {
    return (<ClearAllGroups />); // Clears all groups and aggregates
  } else if (tab === Tab.Filter) {
    return (<ClearAllFilters />);
  }
  return null;
}
