import { BinaryTree, Expr, isFunCall, Scope, SoQLType, TypedExpr, TypedSelect, UnAnalyzedAst } from 'common/types/soql';
import {
  editableExpression,
  editableExpressionNA,
  getColumns,
  getColumnsNA,
  getUnAnalyzedAst,
  getAnalysisProjectionInfo,
  getCompilationProjectionInfo,
  getRightmostLeafFromAnalysis,
  hasQuerySucceeded,
  lastInChain,
  ProjectionInfo,
  ProjectionInfoNA
} from '../lib/selectors';
import { Either, factorOption } from 'common/either';
import { usingNewAnalysisEndpoint, whichAnalyzer } from '../lib/feature-flag-helpers';
import { containsAggregate, hasGroupOrAggregate, makeFilter, makeFilterNA } from '../lib/soql-helpers';
import { AppState, Query } from '../redux/store';
import { Eexpr, EexprNA, FilterType, QueryStatus } from 'common/explore_grid/types';
import React from 'react';
import { connect } from 'react-redux';
import { none, Option, option, some } from 'ts-option';
import * as VisualContainer from './visualContainer';
import ExpressionEditor from './VisualExpressionEditor';
import WithHandlingOfNonVisualStates from './visualNodes/WithHandlingOfNonVisualStates';
import _ from 'lodash';
import AddFilter from './AddFilter';
import I18n from 'common/i18n';
import { getFilterCount, getLayerCount, getLayerCountNA, getOutermostCombinator, getOutermostCombinatorNA } from '../lib/filter-helpers';
import { scrollToPosition } from '../lib/scroll-helpers';
import '../styles/visual-filter-editor.scss';
import { Operator } from './visualNodes/Types';
import { selectors as SelectRemoteStatus } from '../redux/statuses';
const t = (k: string, options = {}, scope = 'shared.explore_grid.visual_filter_editor') => I18n.t(k, { ...options, scope });

interface State {
  addFilterWithOperator: Operator;
}

type FilterSubeditorProps = VisualContainer.VisualContainerProps &
{
  addExpr: (newExpr: Either<Expr, TypedExpr>, soqlType: SoQLType | null, operator: Operator, exprType?: FilterType) => void;
  hasGroupOrAggregate?: boolean;
  projectionInfo: Either<ProjectionInfo, ProjectionInfoNA>;
};

export class VisualWhereEditor extends React.Component<FilterSubeditorProps, State> {
  constructor(props: FilterSubeditorProps) {
    super(props);
    this.state = {
      addFilterWithOperator: this.getDefaultOperator()
    };
  }
  getEditableExpression = (): Option<Either<Eexpr<Expr, TypedExpr>, EexprNA<TypedExpr>>> => {
    return factorOption<Eexpr<Expr, TypedExpr>, EexprNA<TypedExpr>>(whichAnalyzer(
      () => editableExpression(
        this.props.query.compilationResult,
        (unanalyzed) => option(unanalyzed.where),
        (analyzed) => option(analyzed.where)
      ),
      () => editableExpressionNA(
        this.props.query.analysisResult,
        (ast) => option(ast.where),
      )
    )());
  };

  getDefaultOperator = (): Operator => {
    const outermost = this.getEditableExpression().map(either => either.fold(
      eexpr => getOutermostCombinator(some(eexpr)),
      eexpr => getOutermostCombinatorNA(some(eexpr))
    )).getOrElseValue(undefined);
    return outermost === Operator.AND ? Operator.OR : Operator.AND;
  };

  onUpdate = (changed: Either<Expr, TypedExpr>) => {
    const rightmostLeaf = usingNewAnalysisEndpoint()
      ? getRightmostLeafFromAnalysis(this.props.query)
      : getUnAnalyzedAst(this.props.query).map(lastInChain);
    rightmostLeaf.forEach(ast => {
      this.props.compileAST({
        ...ast,
        where: changed.foldEither(w => w)
      }, true);
    });

    this.setState({ addFilterWithOperator: this.getDefaultOperator() });
  };

  onRemove = () => {
    const rightmostLeaf = usingNewAnalysisEndpoint()
      ? getRightmostLeafFromAnalysis(this.props.query)
      : getUnAnalyzedAst(this.props.query).map(lastInChain);
    rightmostLeaf.forEach(ast => {
      this.props.compileAST({
        ...ast,
        where: null
      }, true);
    });
    this.setState({ addFilterWithOperator: this.getDefaultOperator() });
  };

  render() {
    const columns = usingNewAnalysisEndpoint() ? getColumnsNA(this.props.query) : getColumns(this.props.query);
    const querySucceeded = hasQuerySucceeded(this.props.query);

    return (
      <div className="visual-where-editor">
        {
          this.getEditableExpression().match({
            some: (eexpr) => {
              const count = eexpr.fold(getLayerCount, getLayerCountNA);
              return (<div className={`vee-expr-container ${count < 1 ? 'vee-gray-bg' : 'vee-white-bg'}`}>
                <div className={count % 2 === 1 && count >= 1 ? 'vee-expr-container vee-gray-bg' : ''}>
                  <ExpressionEditor
                    // no aggregate filters allowed in here. VisualHavingEditor
                    // does that
                    scope={this.props.scope.filter(fs => !fs.is_aggregate)}
                    isTypeAllowed={(st: SoQLType) => st === SoQLType.SoQLBooleanT}
                    eexpr={eexpr}
                    update={this.onUpdate}
                    remove={this.onRemove}
                    columns={columns}
                    parameters={this.props.parameters}
                    showAddExpr
                    addFilterType={FilterType.WHERE}
                    layer={1}
                    layerCount={count}
                    querySucceeded={querySucceeded}
                    showRemove={false}
                    showKebab
                    hasGroupOrAggregate={this.props.hasGroupOrAggregate}
                    projectionInfo={this.props.projectionInfo}
                  />
                </div>
                {count < 2 && <AddFilter
                  addExpr={this.props.addExpr}
                  className={count >= 1 ? 'add-expr-white-bg' : 'add-expr-gray-bg'}
                  columns={columns}
                  addFilterType={FilterType.WHERE}
                  showOperatorSelector={true}
                  addWithOperator={this.state.addFilterWithOperator}
                  updateOperator={(operator: Operator) => this.setState({ addFilterWithOperator: operator })}
                  removable={true} />}
              </div>);
            },
            none: () => <AddFilter
              addExpr={this.props.addExpr}
              columns={columns}
              addFilterType={FilterType.WHERE}/>
          })
        }
      </div>
    );
  }
}

export class VisualHavingEditor extends React.Component<FilterSubeditorProps, State> {
  constructor(props: FilterSubeditorProps) {
    super(props);
    this.state = {
      addFilterWithOperator: this.getDefaultOperator()
    };
  }

  getEditableExpression = (): Option<Either<Eexpr<Expr, TypedExpr>, EexprNA<TypedExpr>>> =>
    factorOption<Eexpr<Expr, TypedExpr>, EexprNA<TypedExpr>>(whichAnalyzer(
      () => editableExpression(
        this.props.query.compilationResult,
        (unanalyzed) => option(unanalyzed.having),
        (analyzed) => option(analyzed.having)
      ),
      () => editableExpressionNA(
        this.props.query.analysisResult,
        (ast) => option(ast.having)
      )
      )()
    );

  getDefaultOperator = (): Operator => {
    const outermost = this.getEditableExpression().map(either => either.fold(
      eexpr => getOutermostCombinator(some(eexpr)),
      eexpr => getOutermostCombinatorNA(some(eexpr))
    )).getOrElseValue(undefined);
    return outermost === Operator.AND ? Operator.OR : Operator.AND;
  };

  onUpdate = (changed: Either<Expr, TypedExpr>) => {
    const rightmostLeaf = usingNewAnalysisEndpoint()
      ? getRightmostLeafFromAnalysis(this.props.query)
      : getUnAnalyzedAst(this.props.query).map(lastInChain);
    rightmostLeaf.forEach(ast => {
      this.props.compileAST({
        ...ast,
        having: changed.foldEither(w => w)
      }, true);
    });

    this.setState({ addFilterWithOperator: this.getDefaultOperator() });
  };

  onRemove = () => {
    const rightmostLeaf = usingNewAnalysisEndpoint()
      ? getRightmostLeafFromAnalysis(this.props.query)
      : getUnAnalyzedAst(this.props.query).map(lastInChain);
    rightmostLeaf.forEach(ast => {
      this.props.compileAST({
        ...ast,
        having: null
      }, true);
    });
    this.setState({ addFilterWithOperator: this.getDefaultOperator() });
  };

  render() {
    const { query, scope, parameters, projectionInfo } = this.props;

    const columns = usingNewAnalysisEndpoint() ? getColumnsNA(query) : getColumns(query);

    const editor = this.getEditableExpression().match({
      some: (eexpr) => {
        const count = eexpr.fold(getLayerCount, getLayerCountNA);
        return (<div className={`vee-expr-container ${count < 1 ? 'vee-gray-bg' : 'vee-white-bg'}`}>
          <div className={count % 2 === 1 && count >= 1 ? 'vee-expr-container vee-gray-bg' : ''}>
            <ExpressionEditor
              scope={scope}
              eexpr={eexpr}
              isTypeAllowed={(st: SoQLType) => st === SoQLType.SoQLBooleanT}
              update={this.onUpdate}
              remove={this.onRemove}
              columns={columns}
              parameters={parameters}
              showAddExpr
              addFilterType={FilterType.HAVING}
              layer={1}
              layerCount={count}
              showRemove={false}
              showKebab
              hasGroupOrAggregate
              projectionInfo={projectionInfo}
            />
          </div>
          {count < 2 && <AddFilter
            addExpr={(expr: Either<Expr, TypedExpr>, soqlType: SoQLType | null, operator: Operator) => this.props.addExpr(expr, soqlType, operator, FilterType.HAVING)}
            columns={columns}
            className={count >= 1 ? 'add-expr-white-bg' : 'add-expr-gray-bg'}
            addFilterType={FilterType.HAVING}
            showOperatorSelector={true}
            addWithOperator={this.state.addFilterWithOperator}
            updateOperator={(operator: Operator) => this.setState({ addFilterWithOperator: operator })}
            removable={true} /> }
        </div>);
      },
      none: () => <AddFilter
      addExpr={(expr: Either<Expr, TypedExpr>, soqlType: SoQLType | null, operator: Operator) => this.props.addExpr(expr, soqlType, operator, FilterType.HAVING)}
      columns={columns}
      addFilterType={FilterType.HAVING}/>
    });

    return (
      <div className="visual-having-editor">
        {editor}
      </div>
    );
  }
}

enum FilterTab {
  Where = 'where',
  Having = 'having'
}

interface VisualFilterEditorState {
  activeTab: FilterTab;
  alertDismissed: boolean;
  scrollPosition: Option<number>;
}

type VisualFilterEditorProps = VisualContainer.VisualContainerProps & {
  subTab: Option<string>;
};

export class VisualFilterEditor extends React.Component<VisualFilterEditorProps, VisualFilterEditorState> {
  constructor(props: VisualFilterEditorProps) {
    super(props);
    const defaultTab = this.getDefaultActiveTab(props.query, props.scope);
    const subTab = props.subTab.getOrElseValue('');
    this.state = {
      activeTab: this.isSubTabValid(subTab) ? subTab as FilterTab : defaultTab,
      alertDismissed: false,
      scrollPosition: none
    };
  }

  getDefaultActiveTab(query: Query, scope: Scope): FilterTab {
    const rightmostLeaf = usingNewAnalysisEndpoint()
      ? getRightmostLeafFromAnalysis(this.props.query)
      : getUnAnalyzedAst(this.props.query).map(lastInChain);
    return hasGroupOrAggregate(rightmostLeaf, scope)
      ? FilterTab.Having
      : FilterTab.Where;
  }

  isSubTabValid(subTab: string): boolean {
    const validTabs = Object.values(FilterTab) as string[];
    return validTabs.includes(subTab);
  }

  getSnapshotBeforeUpdate(): Option<number> {
    // Grab the scroll position we're at before we update.
    const element = document.querySelector('.scroll-container > div');
    if (element) {
      return some(element.getBoundingClientRect().top);
    }
    return none;
  }

  componentDidUpdate(prevProps: VisualFilterEditorProps, prevState: VisualFilterEditorState, snapshot: Option<number>) {
    const newActiveTab = this.getDefaultActiveTab(this.props.query, this.props.scope);
    const newSubTab = this.props.subTab.getOrElseValue('');
    const subTabValid = this.isSubTabValid(newSubTab);
    if (this.getDefaultActiveTab(prevProps.query, prevProps.scope) !== newActiveTab && !subTabValid) {
      this.setState({ activeTab: newActiveTab });
    } else if (subTabValid && newSubTab !== this.state.activeTab) {
      this.setState({ activeTab: newSubTab as FilterTab });
    }

    const remoteStatus = this.props.remoteStatusInfo;
    if (SelectRemoteStatus.inProgress(remoteStatus).nonEmpty &&
      !this.state.scrollPosition.isDefined && snapshot.isDefined) {
      // We show a compiling message after an edit, which is what loses the scroll position.
      // So we save the scroll position we had just before compilation to state.
      this.setState({ scrollPosition: snapshot });
    } else if ((SelectRemoteStatus.canRunCompiledQuery(remoteStatus).nonEmpty ||
      SelectRemoteStatus.queryRanSuccessfully(remoteStatus).nonEmpty) &&
      this.state.scrollPosition.isDefined) {
      // Once compilation succeeds and we're showing the VEE again, scroll to the saved position.
      scrollToPosition('.scroll-container > div', this.state.scrollPosition.getOrElseValue(0));
      this.setState({ scrollPosition: none });
    }
  }

  getAppliedAST = (): Option<BinaryTree<UnAnalyzedAst>> =>  {
    return this.props.query.queryResult.flatMap(result => {
      if (result.type === QueryStatus.QUERY_SUCCESS) {
        if (usingNewAnalysisEndpoint()) {
          return result.analyzed.map(a => a.ast);
        } else {
          return some(result.compiled.unanalyzed);
        }
      }
      return none;
    });
  };

  getHavingFilterCount(): number {
    return this.getAppliedAST()
      .map(lastInChain)
      .flatMap(ast => option(ast.having))
      .match({
        some: expr => getFilterCount(expr),
        none: () => 0
      });
  }

  getWhereFilterCount(): number {
    return this.getAppliedAST()
      .map(lastInChain)
      .flatMap(ast => option(ast.where))
      .match({
        some: expr => getFilterCount(expr),
        none: () => 0
      });
  }

  getAst = (): Option<Either<UnAnalyzedAst, TypedSelect>> => factorOption<UnAnalyzedAst, TypedSelect>(whichAnalyzer(
    (query) => getUnAnalyzedAst(query).map(lastInChain),
    (query) => getRightmostLeafFromAnalysis(query)
  )(this.props.query));

  addFilter = (expr: Either<Expr, TypedExpr>, soqlType: SoQLType | null, operator: Operator, filterType?: FilterType) => {
    let newAst: Option<UnAnalyzedAst | TypedSelect> = none;
    if (filterType === FilterType.HAVING || (expr.foldEither(isFunCall) && expr.foldEither(e => containsAggregate(this.props.scope, e)))) {
      // add having
      if (soqlType) {
        newAst = this.getAst().map(astEither => {
          if (usingNewAnalysisEndpoint()) {
            const ast = astEither.right, e = expr.right;
            return { ...ast, having: makeFilterNA(e, soqlType, ast.having, operator) };
          } else {
            const ast = astEither.left, e = expr.left;
            return { ...ast, having: makeFilter(e, soqlType, ast.having, operator) };
          }
        });
      }
    } else {
      // add to where
      if (soqlType) {
        newAst = this.getAst().map(astEither => {
          if (usingNewAnalysisEndpoint()) {
            const ast = astEither.right, e = expr.right;
            return { ...ast, where: makeFilterNA(e, soqlType, ast.where, operator) };
          } else {
            const ast = astEither.left, e = expr.left;
            return { ...ast, where: makeFilter(e, soqlType, ast.where, operator) };
          }
        });
      }
    }

    newAst.forEach(ast => this.props.compileAST(ast, true));
  };

  render() {
    const { query, scope } = this.props;
    const { activeTab, alertDismissed } = this.state;

    const canShowHavingUI = hasGroupOrAggregate(this.getAst().map(a => a.get), scope);
    const projectionInfo = whichAnalyzer(getCompilationProjectionInfo, getAnalysisProjectionInfo)(query);

    return (
      <div className="grid-datasource-components visual-filter-editor">
        {canShowHavingUI && <div className="filter-tabs-container">
          {!alertDismissed && <div className="alert info filter-grouping-alert">
            <span>{t('grouped_alert')}</span>
            <button onClick={() => this.setState({ alertDismissed: true })} className="btn btn-link" aria-label={t('dismiss_alert')}>
              <span className="socrata-icon-close-2"></span>
            </button>
          </div>}
          <ul className="nav-tabs filter-tabs" role="tablist">
            <li className={`filter-tab-item ${activeTab === FilterTab.Having ? 'current' : ''}`}>
              <a
                onClick={() => this.setState({ activeTab: FilterTab.Having })}
                role="tab"
                aria-selected={activeTab === FilterTab.Having}
                aria-controls="vee-filter-tabpanel">
                {t('grouped_aggregated_columns')}
                <span className="filter-count">({t('filter_count', { count: this.getHavingFilterCount() })})</span>
              </a>
            </li>
            <li className={`filter-tab-item ${activeTab === FilterTab.Where ? 'current' : ''}`}>
              <a
                onClick={() => this.setState({ activeTab: FilterTab.Where })}
                role="tab"
                aria-selected={activeTab === FilterTab.Where}
                aria-controls="vee-filter-tabpanel">
                {t('other_columns')}
                <span className="filter-count">({t('filter_count', { count: this.getWhereFilterCount() })})</span>
              </a>
            </li>
          </ul>
        </div>}
        <div className="filter-scroll-container scroll-container" id="vee-filter-tabpanel">
          <WithHandlingOfNonVisualStates query={this.props.query} remoteStatusInfo={this.props.remoteStatusInfo}>
            {this.state.activeTab === FilterTab.Where || !canShowHavingUI ?
              <VisualWhereEditor
                {...this.props }
                addExpr={this.addFilter}
                hasGroupOrAggregate={canShowHavingUI}
                projectionInfo={projectionInfo} />
              : <VisualHavingEditor
                {...this.props }
                addExpr={this.addFilter}
                projectionInfo={projectionInfo}/>}
          </WithHandlingOfNonVisualStates>
        </div>
      </div>
    );
  }

}

const mapStateToProps = (state: AppState, props: VisualContainer.ExternalProps) => {
  return {
    ...VisualContainer.mapStateToProps(state, props),
    subTab: state.locationParams.subTab
  };
};

export default connect(
  mapStateToProps,
  VisualContainer.mapDispatchToProps,
  VisualContainer.mergeProps
)(VisualFilterEditor);
