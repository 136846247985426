import React from 'react';
import Dropdown from 'common/components/Dropdown';
import { PicklistOption } from 'common/components/Picklist';
import SocrataIcon, { IconName } from 'common/components/SocrataIcon';
import I18n from 'common/i18n';
import { TypedExpr, isSortable, SoQLType } from 'common/types/soql';
import { picklistSizingStrategy } from 'common/components/Dropdown/picklistSizingStrategy';
import { ProjectableTerm } from './common';
import Flyout from 'common/components/Flyout';

const t = (k: string, options = {}) => I18n.t(k, { ...options, scope: 'shared.explore_grid.visual_column_manager.sort_selector' });

interface Props {
  projectableTerm: ProjectableTerm;
  addOrReplaceOrderBy: (expr: TypedExpr, ascending: boolean, sortIndex: number) => void;
  clearOrderBy: (sortIndex: number) => void;
  id: string;
}

enum SortOptions {
  Ascending = 'ascending',
  Descending = 'descending'
}

const sortDisplayTuple = (ascending: boolean, clear: boolean, columnType: SoQLType): [JSX.Element, string] => {
  const useAlpha = columnType === SoQLType.SoQLTextT;

  if (ascending) {
    const icon = useAlpha ? IconName.SortAz : IconName.SortAsc;
    const text = clear ? 'clear_ascending_sort' : 'ascending_sort';
    return [<SocrataIcon key="icon" name={icon} />, t(text)];
  } else {
    const icon = useAlpha ? IconName.SortZa : IconName.SortDesc;
    const text = clear ? 'clear_descending_sort' : 'descending_sort';
    return [<SocrataIcon key="icon" name={icon} />, t(text)];
  }
};

const caret = <SocrataIcon name={IconName.ArrowDown} className="dropdown-caret" key="dropdown-caret" />;
const noSort = () => t('no_sort');

type ExistingSort = (JSX.Element | string)[];
class SortSelector extends React.Component<Props> {
  displayState = () => this.props.projectableTerm.sortData.orderBy.match<ExistingSort | string>({
    some: ob => sortDisplayTuple(ob.ascending, false, ob.expr.soql_type!).concat(caret),
    none: () => noSort()
  });

  actionOptions() {
    const { orderBy } = this.props.projectableTerm.sortData;
    const [currentSort, columnType] = orderBy.match<[boolean | undefined, SoQLType | null]>({
      some: ob => [ob.ascending, ob.expr.soql_type],
      none: () => [undefined, null]
    });

    return [true, false].map(sortDirection => {
      const [icon, title] = sortDisplayTuple(sortDirection, currentSort === sortDirection, columnType!);
      return {
        title, icon,
        value: sortDirection ? SortOptions.Ascending : SortOptions.Descending
      };
    });
  }

  onSelect({ value }: PicklistOption) {
    const { sortData } = this.props.projectableTerm;
    const expr  = this.props.projectableTerm.expr.get.expr;
    const { orderBy, sortIndex } = sortData;
    const currentSort = orderBy.match<boolean | undefined>({
      some: ob => ob.ascending,
      none: () => undefined
    });

    switch (value) {
      case SortOptions.Ascending:
        if (currentSort === true) {
          this.props.clearOrderBy(sortIndex);
        } else {
          this.props.addOrReplaceOrderBy(expr, true, sortIndex);
        }
        break;
      case SortOptions.Descending:
        if (currentSort === false) {
          this.props.clearOrderBy(sortIndex);
        } else {
          this.props.addOrReplaceOrderBy(expr, false, sortIndex);
        }
        break;
    }
  }

  isDisabled(projectableTerm: ProjectableTerm): boolean {
    if (projectableTerm.expr.nonEmpty && projectableTerm.expr.get.expr.soql_type !== null) {
      return !isSortable(projectableTerm.expr.get.expr.soql_type);
    } else return false;
  }

  render() {
    const disabled = this.isDisabled(this.props.projectableTerm);
    const dropdown = (
    <Dropdown
      className="sort-selector"
      placeholder={this.displayState()}
      options={this.actionOptions()}
      size="small"
      id={`${this.props.id}-dropdown`}
      picklistSizingStrategy={picklistSizingStrategy.EXPAND_TO_WIDEST_ITEM}
      onSelection={(option: PicklistOption) => this.onSelect(option)}
      disabled={this.isDisabled(this.props.projectableTerm)}
    />);

    if (disabled) {
      const soqlDataType = this.props.projectableTerm.expr.map(expr => {
        if (expr.expr.soql_type) {
          return expr.expr.soql_type;
        } else return '';
      }).getOrElseValue('');
      return (
        <Flyout text={t('cannot_sort', {soqlDataType: soqlDataType} )}>
          {dropdown}
        </Flyout>
      );
    } else {
      return dropdown;
    }
  }
}

export default SortSelector;
