import React from 'react';
import { fetchTranslation } from 'common/locale';
import { ForgeCircularProgress } from '@tylertech/forge-react';
const t = (k: string) => fetchTranslation(k, 'shared.explore_grid.compiling');

const Compiling = () => (
  <div className="compiling" >
    <ForgeCircularProgress
      progressbar-aria-label={t('loading')}
    />
     <p role="status" className="text-quiet">{t('loading')}</p>
  </div>
);

export default Compiling;
