import React from 'react';
import _ from 'lodash';
import { fetchTranslation } from 'common/locale';
import {
  ForgeButton,
  ForgeCard,
  ForgeCheckbox,
  ForgeDatePicker,
  ForgeDialog,
  ForgeIcon,
  ForgeIconButton,
  ForgeLabelValue,
  ForgeOption,
  ForgeRadio,
  ForgeTextField,
  ForgeTooltip,
  ForgeToolbar,
  ForgeScaffold,
  ForgeSelect
} from '@tylertech/forge-react';
import {
  SuggestedValuesType,
  ClientContextVariable,
  ClientContextVariableCreate,
  SpecificValue,
  SpecificValues,
  getSuggestedValuesTranslation
} from 'common/types/clientContextVariable';
import { Dispatcher } from '../redux/actions';
import { connect } from 'react-redux';
import { SoQLType } from 'common/types/soql';
import * as Actions from '../redux/actions';
import { dateToString } from '../lib/soql-helpers';
import { AppState, OpenModalType, AppStateContextualEventHandlers } from '../redux/store';
import { none, Option, some, option } from 'ts-option';
import CopyToClipboard from 'react-copy-to-clipboard';
import { getParameterFunction } from 'common/core/client_context_variables';
import { FeatureFlags } from 'common/feature_flags';
import { ParameterInput } from './ParameterInput';

const t = (k: string) => fetchTranslation(k, 'shared.explore_grid.parameters_editor_modal');
const u = (k: string) => fetchTranslation(k, 'shared.explore_grid.parameters_editor.data_types');
const v = (k: string) => fetchTranslation(k, 'shared.explore_grid.parameters_editor.suggested_values');

type ParameterErrors = {
  apiFieldName: boolean;
  apiFieldNameErrorMessage: string;
  displayName: boolean;
  displayNameErrorMessage: string;
  defaultValue: boolean;
  defaultValueErrorMessage: string;
  specificValueMessages: Map<string, string>;
};

type InputParams = {
  type: SoQLType;
  value: string;
  index: number;
  onChange: (v: any) => void;
  invalid: boolean;
  validationMessage: string;
  idPrefix: string;
};

interface DispatchProps {
  closeModal: () => void;
  onApplyChanges: () => void;
  onCodeSave: () => void;
  dispatch: Dispatcher;
}

interface StateProps {
  isOpen: boolean;
  isEditing: boolean;
  viewId: string;
  publishedViewId: Option<string>;
  parameters: ClientContextVariable[];
  parameterToEdit: Option<ClientContextVariable>;
  contextualEventHandlers: AppStateContextualEventHandlers;
}

type Props = StateProps & {
  closeModal: DispatchProps['closeModal'];
  onApplyChanges: DispatchProps['onApplyChanges'];
  onCodeSave: DispatchProps['onCodeSave'];
  addParameter: (
    viewId: string,
    parameter: ClientContextVariableCreate,
    onSuccess: () => void,
    onError: (err: any) => void
  ) => void;
  editParameter: (
    viewId: string,
    parameter: ClientContextVariableCreate,
    onSuccess: () => void,
    onError: (err: any) => void
  ) => void;
};

enum parameterErrorCodes {
  wrongDefaultType = 'CLIENT_CONTEXT_VARIABLE.DEFAULT_TYPE_INCORRECT',
  defaultTypeCheck = 'CLIENT_CONTEXT_VARIABLE.DEFAULT_DATATYPE_CHECK',
  whitespace = 'CLIENT_CONTEXT_VARIABLE.NO_WHITESPACE_NAME',
  uniqueName = 'CLIENT_CONTEXT_VARIABLE.UNIQUE_NAME'
}

export interface State {
  isEditing: boolean;
  apiFieldName?: string;
  displayName?: string;
  type: SoQLType;
  defaultValue: string;
  suggestedValuesType: SuggestedValuesType;
  specificValues: SpecificValue[];
  // when we swap types or suggested values we put a back up here. Once the user starts updating values after swapping we clear this
  backupSpecificValues: { type: SoQLType; specificValues: SpecificValue[] } | null;
  errors: ParameterErrors;
}

export class ParametersEditorModal extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isEditing: false,
      type: SoQLType.SoQLTextT,
      errors: {
        apiFieldName: false,
        apiFieldNameErrorMessage: '',
        displayName: false,
        displayNameErrorMessage: '',
        defaultValue: false,
        defaultValueErrorMessage: '',
        specificValueMessages: new Map<string, string>()
      },
      defaultValue: '',
      suggestedValuesType: SuggestedValuesType.ANY_VALUES,
      specificValues: [],
      backupSpecificValues: null
    };
  }

  // parameterToEdit lives in AppState. When it is set, populate this component's default state with the values of the parameter
  static getDerivedStateFromProps = (props: Props, state: State) => {
    if (props.isEditing && !state.isEditing) {
      const pte = props.parameterToEdit.get;
      return {
        ...state,
        isEditing: true,
        apiFieldName: pte.name,
        displayName: pte.displayName,
        type: pte.dataType,
        defaultValue: pte.defaultValue,
        suggestedValuesType: pte.suggestedValuesType,
        specificValues: pte.suggestedValues?.valueList || null
      };
    } else if (!props.isEditing && state.isEditing) {
      return {
        ...state,
        isEditing: false,
        apiFieldName: undefined,
        displayName: undefined,
        type: SoQLType.SoQLTextT,
        defaultValue: '',
        allowedValues: SuggestedValuesType.ANY_VALUES,
        specificValues: []
      };
    }
    return null;
  };

  getInitialSpecificValues = (optionalFirstValue?: string): SpecificValue[] => {
    return [
      { value: optionalFirstValue || '', displayName: '' },
      { value: '', displayName: '' }
    ];
  };

  clearErrorCodes = () => {
    this.setState(() => ({
      errors: {
        apiFieldName: false,
        apiFieldNameErrorMessage: '',
        displayName: false,
        displayNameErrorMessage: '',
        defaultValue: false,
        defaultValueErrorMessage: '',
        specificValueMessages: new Map<string, string>()
      }
    }));
  };

  clearComponentState = () => {
    this.setState({
      isEditing: false,
      apiFieldName: undefined,
      displayName: undefined,
      type: SoQLType.SoQLTextT,
      defaultValue: '',
      suggestedValuesType: SuggestedValuesType.ANY_VALUES,
      specificValues: [],
      backupSpecificValues: null
    });
  };

  handleErrorCodes = (code: parameterErrorCodes, clearAllErrors = false) => {
    if (clearAllErrors) {
      this.clearErrorCodes();
    }
    switch (code) {
      case parameterErrorCodes.wrongDefaultType: {
        const translatedMessage = t('default_type_incorrect');
        this.setState((prevState) => ({
          errors: {
            ...prevState.errors,
            defaultValue: true,
            defaultValueErrorMessage: translatedMessage
          }
        }));
        break;
      }
      case parameterErrorCodes.defaultTypeCheck: {
        const translatedMessage = t('default_type_check');
        this.setState((prevState) => ({
          errors: {
            ...prevState.errors,
            defaultValue: true,
            defaultValueErrorMessage: translatedMessage
          }
        }));
        break;
      }
      case parameterErrorCodes.whitespace: {
        const translatedMessage = t('whitespace');
        this.setState((prevState) => ({
          errors: {
            ...prevState.errors,
            apiFieldName: true,
            apiFieldNameErrorMessage: translatedMessage
          }
        }));
        break;
      }
      case parameterErrorCodes.uniqueName: {
        const translatedMessage = t('unique_name');
        this.setState((prevState) => ({
          errors: {
            ...prevState.errors,
            apiFieldName: true,
            apiFieldNameErrorMessage: translatedMessage
          }
        }));
        break;
      }
    }
  };

  onSave = () => {
    const { parameterToEdit } = this.props;
    const {
      apiFieldName,
      displayName,
      type,
      defaultValue,
      suggestedValuesType: suggestedValuesType,
      specificValues
    } = this.state;
    if (!apiFieldName || !defaultValue) {
      console.error(
        `Save requested with invalid apiFieldName: ${apiFieldName} and defaultValue: ${defaultValue} values. Save functionality should have been disabled.`
      );
      return;
    }

    let saveSpecificValues: SpecificValues | null = null;
    if (suggestedValuesType === SuggestedValuesType.SPECIFIC_VALUES) {
      saveSpecificValues = {
        valueList: specificValues
          .map(
            // trim all values, remove displayName if empty string
            (specificValue) => ({
              value: specificValue.value.trim(),
              displayName: specificValue.displayName?.trim() ? specificValue.displayName.trim() : undefined
            })
          )
          .filter(
            // only keep specific values if they have values
            (specificValue) => specificValue.value !== ''
          )
      };
    }

    const newAPIParameter: ClientContextVariableCreate = {
      name: apiFieldName!,
      displayName: displayName || '',
      dataType: type,
      defaultValue: defaultValue!,
      suggestedValues: saveSpecificValues || undefined,
      suggestedValuesType: suggestedValuesType
    };

    const onError = (err: any) => {
      if (err.json?.error && err.json?.code) {
        this.handleErrorCodes(err.json.code, true);
      } else {
        console.error('Error creating new parameter: ', err);
      }
    };
    const onSuccess = () => {
      this.props.onApplyChanges();
      this.clearComponentState();
    };

    if (parameterToEdit.nonEmpty) {
      this.props.editParameter(this.props.viewId, newAPIParameter, onSuccess, onError);
    } else {
      this.props.addParameter(this.props.viewId, newAPIParameter, onSuccess, onError);
    }
  };

  getDefaultValueInput = (): any => {
    const inputProps: InputParams = {
      type: this.state.type,
      value: this.state.defaultValue,
      index: 0,
      invalid: this.state.errors.defaultValue,
      validationMessage: this.state.errors.defaultValueErrorMessage,
      onChange: this.state.type === 'calendar_date' ? this.onDefaultDateChange : this.onDefaultValueChange,
      idPrefix: 'input-default'
    };

    return this.getValueInput(inputProps);
  };

  getValueInput = (params: InputParams): any => {
    const { type, value, index, onChange, invalid, validationMessage, idPrefix } = params;

    switch (type) {
      case 'text':
      case 'number': {
        const id = `${idPrefix}-text-${index}`;
        return (
          <ForgeTextField invalid={invalid}>
            <input
              type="text"
              id={id}
              data-testid={id}
              value={value}
              onChange={onChange}
              autoComplete="off"
            />
            {index === 0 && (
              <label htmlFor={id} slot="label">
                {t('default')}
              </label>
            )}
            {invalid ? <span slot="helper-text">{validationMessage}</span> : null}
          </ForgeTextField>
        );
      }
      case 'calendar_date': {
        const id = `${idPrefix}-date-${index}`;
        return (
          <ForgeDatePicker
            popup-classes="datatype-popup"
            on-forge-date-picker-change={onChange}
            allowInvalidDate={true}
            value={value}
            data-testid={`${id}-forge-date-picker`}
          >
            <ForgeTextField>
              <input type="text" id={id} data-testid={id} />
              {index === 0 && <label htmlFor={id}>{t('default')}</label>}
              {invalid ? (
                <span id="datePickerError" slot="helper-text">
                  {validationMessage}
                </span>
              ) : null}
            </ForgeTextField>
          </ForgeDatePicker>
        );
      }
      case 'checkbox': {
        const id = `${idPrefix}-checkbox-${index}`;
        return (
          <ForgeLabelValue>
            {index === 0 && <span slot="label">{t('default')}</span>}
            <span slot="value">
              <div
                role="radiogroup"
                aria-label={t('aria_label')}
                id={id}
                data-testid={id}
                onChange={onChange}
              >
                <ForgeRadio>
                  <input
                    type="radio"
                    data-testid={`${id}-true`}
                    id="radio-1"
                    name="radios"
                    value="true"
                    defaultChecked={value == 'true'}
                  />
                  <label htmlFor="radio-1">{t('true')}</label>
                </ForgeRadio>
                <ForgeRadio>
                  <input
                    type="radio"
                    id="radio-2"
                    data-testid={`${id}-false`}
                    name="radios"
                    value="false"
                    defaultChecked={value == 'false'}
                  />
                  <label htmlFor="radio-2">{t('false')}</label>
                </ForgeRadio>
                {invalid ? <span slot="helper-text">{validationMessage}</span> : null}
              </div>
            </span>
          </ForgeLabelValue>
        );
      }
    }
  };

  getPreview = (): any => {
    const getId = () => {
      if (this.state.suggestedValuesType === SuggestedValuesType.SPECIFIC_VALUES) {
        return 'parameter-preview-specific-values';
      }
      if ([SoQLType.SoQLTextT, SoQLType.SoQLNumberT].includes(this.state.type)) {
        return 'parameter-preview-text';
      }
      if (SoQLType.SoQLFloatingTimestampT === this.state.type) {
        return 'parameter-preview-date';
      }
      if (SoQLType.SoQLBooleanT === this.state.type) {
        return 'parameter-preview-checkbox';
      }
    };
    return (
      <ParameterInput
        label={this.state.displayName}
        id={getId() || ''}
        dataType={this.state.type}
        value={this.state.defaultValue}
        readOnly={this.state.suggestedValuesType !== SuggestedValuesType.SPECIFIC_VALUES}
        onChange={() => {}}
        suggestedValuesType={this.state.suggestedValuesType}
        suggestedValues={this.state.specificValues}
      />
    );
  };

  onFieldNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (RegExp(/\s/).test(e.target.value)) {
      // check for spaces
      this.handleErrorCodes(parameterErrorCodes.whitespace);
    } else if (this.props.parameters.find((pm) => !pm.inherited && pm.name === e.target.value)) {
      // check for uniqueness
      this.handleErrorCodes(parameterErrorCodes.uniqueName);
    } else {
      // if there are no errors, clear the error codes
      this.setState((previousState) => ({
        errors: {
          ...previousState.errors,
          apiFieldName: false,
          apiFieldNameErrorMessage: ''
        }
      }));
    }

    this.setState({ apiFieldName: e.target.value });
  };

  onDataTypeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newType = e.target.value as SoQLType;
    // make sure we are actually changing the type
    if (newType === this.state.type) return;

    this.setState((prevState) => ({
      ...prevState,
      errors: {
        ...prevState.errors,
        defaultValue: false,
        defaultValueErrorMessage: '',
        specificValueMessages: this.validateSpecificMessages(
          prevState.specificValues,
          e.target.value as SoQLType
        )
      },
      type: newType,
      defaultValue: '',
      // when updating to checkbox, you cannot use specific values
      ...(newType === 'checkbox' && {
        suggestedValuesType: SuggestedValuesType.ANY_VALUES,
        specificValues: [],
        backupSpecificValues: { type: prevState.type, specificValues: prevState.specificValues }
      }),
      // when swapping to or from calendar date, clear specific values
      ...((newType === 'calendar_date' || prevState.type === 'calendar_date') && {
        specificValues: this.getCompatibleBackUpSpecificValues(newType),
        backupSpecificValues: { type: prevState.type, specificValues: prevState.specificValues }
      })
    }));
  };

  getCompatibleBackUpSpecificValues = (newType: SoQLType, optionalDefaultValue?: string) => {
    const backUpType = this.state.backupSpecificValues?.type;

    if (this.state.backupSpecificValues) {
      if (
        (newType === 'text' || newType === 'number') &&
        (backUpType === 'text' || backUpType === 'number')
      ) {
        return this.state.backupSpecificValues.specificValues;
      } else if (
        newType === SoQLType.SoQLFloatingTimestampT &&
        backUpType === SoQLType.SoQLFloatingTimestampT
      ) {
        return this.state.backupSpecificValues.specificValues;
      }
    }
    return this.getInitialSpecificValues(optionalDefaultValue);
  };

  onSuggestedValuesTypeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newSuggestedValue = e.target.value as SuggestedValuesType;
    // check if we are actually changing allowed values
    if (this.state.suggestedValuesType === newSuggestedValue) return;

    const maybeBackupSuggestedValues = this.getCompatibleBackUpSpecificValues(
      this.state.type,
      this.state.defaultValue
    );

    if (newSuggestedValue === SuggestedValuesType.SPECIFIC_VALUES) {
      this.setState((prevState) => ({
        ...prevState,
        suggestedValuesType: newSuggestedValue,
        specificValues: maybeBackupSuggestedValues,
        defaultValue: maybeBackupSuggestedValues[0]?.value || prevState.defaultValue || ''
      }));
    } else {
      // swapping from specific values
      this.setState((prevState) => ({
        ...prevState,
        suggestedValuesType: newSuggestedValue,
        backupSpecificValues: { type: prevState.type, specificValues: prevState.specificValues }
      }));
    }
  };

  onDefaultValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { type: dataType } = this.state;
    const {
      target: { value: newValue }
    } = event;

    switch (dataType) {
      case 'text':
      case 'checkbox':
        this.setState((prevState) => ({
          ...prevState,
          defaultValue: newValue,
          backupSpecificValues: null // clear any backup values once you input new ones
        }));
        break;
      case 'number':
        const defaultValueErrorMessage = isNaN(Number(newValue)) ? t('default_type_not_number') : '';
        this.setState((prevState) => ({
          ...prevState,
          defaultValue: newValue,
          errors: {
            ...this.state.errors,
            defaultValue: !!defaultValueErrorMessage,
            defaultValueErrorMessage
          },
          backupSpecificValues: null // clear any backup values once you input new ones
        }));
        break;
    }
  };

  getDateValue = (value: any) => {
    // annoyingly the forge date picker sets its value to the string 'Invalid date' when its given an invalid date
    if (!value || value === 'Invalid date') {
      return '';
    } else {
      return dateToString(value, SoQLType.SoQLFloatingTimestampT).get;
    }
  };

  onDefaultDateChange = (event: CustomEvent) => {
    this.setState((prevState) => ({
      ...prevState,
      defaultValue: this.getDateValue(event.detail),
      backupSpecificValues: null // clear any backup values once you input new ones
    }));
  };

  addNewOption = () => {
    this.setState({ specificValues: [...this.state.specificValues, { value: '', displayName: '' }] });
  };

  validateSpecificMessages = (specificValues: SpecificValue[], type: SoQLType) => {
    const specificErrorMessages = new Map<string, string>();

    if (type === SoQLType.SoQLNumberT) {
      specificValues.forEach((specificValue, index) => {
        if (specificValue.value && isNaN(Number(specificValue.value))) {
          specificErrorMessages.set(index.toString(), t('default_type_not_number'));
        }
      });
    }
    return specificErrorMessages;
  };

  onOptionValueChange = (index: number, value: string) => {
    const newSpecificValues = [...this.state.specificValues];
    newSpecificValues[index].value = value;

    this.setState((prevState) => ({
      ...prevState,
      specificValues: newSpecificValues,
      // clear any backup values
      backupSpecificValues: null,
      // only set the default value if we are index zero
      ...(index === 0 && { defaultValue: value || '' }),
      errors: {
        ...this.state.errors,
        specificValueMessages: this.validateSpecificMessages(newSpecificValues, prevState.type)
      }
    }));
  };

  onOptionDisplayNameChange = (index: number, value: string) => {
    const newSpecificValues = [...this.state.specificValues];
    newSpecificValues[index].displayName = value;

    this.setState({ specificValues: newSpecificValues });
  };

  removeOption = (removeIndex: number) => {
    const newSpecificValues = this.state.specificValues.filter((_value, index) => index != removeIndex);

    this.setState((prevState) => ({
      ...prevState,
      specificValues: newSpecificValues,
      // if the first option is removed, update the default value
      ...(removeIndex === 0 && { defaultValue: newSpecificValues[0].value }),
      errors: {
        ...this.state.errors,
        specificValueMessages: this.validateSpecificMessages(newSpecificValues, prevState.type)
      }
    }));
  };

  getSpecificValueInput = (specificValue: SpecificValue, index: number) => {
    const inputProps: InputParams = {
      type: this.state.type,
      value: specificValue.value,
      index: index,
      invalid: this.state.errors.specificValueMessages.has(index.toString()),
      validationMessage: this.state.errors.specificValueMessages.get(index.toString()) || '',
      onChange:
        this.state.type === 'calendar_date'
          ? (e) => this.onOptionValueChange(index, this.getDateValue(e.detail))
          : (e) => this.onOptionValueChange(index, e.target.value),
      idPrefix: 'input-specific-value'
    };

    return this.getValueInput(inputProps);
  };

  getSpecificValues = () => {
    const specificValuesHeader = (
      <div key={-1} className="specific-value-option-container-header">
        <h6 className="forge-typography--headline6 specific-option-headers">{t('option_value')}</h6>
        <h6 className="forge-typography--headline6 specific-option-headers">{t('option_display_name')}</h6>
      </div>
    );
    const specificValueOptions = this.state.specificValues.map((specificValue, index) => (
      <div key={index} className="specific-value-option-container">
        <div className="specific-value-input">{this.getSpecificValueInput(specificValue, index)}</div>

        <div className="specific-value-input">
          <div>
            <ForgeTextField>
              <input
                type="text"
                data-testid={`input-specific-value-display-name-${index}`}
                value={specificValue.displayName || ''}
                onChange={(e) => this.onOptionDisplayNameChange(index, e.target.value)}
                autoComplete="off"
              />
            </ForgeTextField>
          </div>
        </div>

        {this.state.specificValues.length > 1 ? (
          <ForgeIconButton>
            <button
              type="button"
              aria-label={t('delete_specific_option')}
              data-testid={`delete-specific-value-${index}`}
              onClick={() => this.removeOption(index)}
            >
              <ForgeIcon name="close" />
            </button>
            <ForgeTooltip>{t('delete_specific_option')}</ForgeTooltip>
          </ForgeIconButton>
        ) : (
          <span className="remove-option-placeholder" />
        )}
      </div>
    ));

    return (
      <div>
        {specificValuesHeader}
        {specificValueOptions}
        <ForgeButton className="add-option-button">
          <button data-testid="add-specific-option" onClick={this.addNewOption}>
            <ForgeIcon name="add" />
            <span>{t('add_option')}</span>
          </button>
        </ForgeButton>
      </div>
    );
  };

  getCodeSnippet = () => {
    const { apiFieldName } = this.state;
    const { viewId, publishedViewId } = this.props;

    return getParameterFunction(apiFieldName || '', publishedViewId.getOrElseValue(viewId));
  };

  copyCodeSnippet = () => {
    this.props.onCodeSave();
  };

  onClose = () => {
    this.clearComponentState();
    this.clearErrorCodes();
    this.props.closeModal();
  };

  render() {
    const {
      apiFieldName,
      defaultValue,
      errors: {
        apiFieldName: apiFieldNameError,
        displayName: displayNameError,
        defaultValue: defaultValueError,
        specificValueMessages: specificValueMessages
      }
    } = this.state;
    const { isOpen, isEditing } = this.props;

    const enableParametersEnhancement = FeatureFlags.value('enable_parameters_enhancements');

    const fieldHasError =
      apiFieldNameError || displayNameError || defaultValueError || specificValueMessages.size;

    const disableSave = !apiFieldName || !defaultValue || fieldHasError;

    const codeSnippet = this.getCodeSnippet();

    const dialogAttributes = new Map([['aria-labelledby', 'parameter-dialog-title']]);

    if (!isOpen) {
      return null;
    }

    return (
      <ForgeDialog open={true} onDismiss={this.onClose} options={{ backdropClose: false, dialogAttributes }}>
        <div
          className={
            enableParametersEnhancement ? 'parameters-editor-dialog' : 'parameters-editor-dialog-legacy'
          }
        >
          <ForgeScaffold>
            <ForgeToolbar slot="header" className="header">
              <h1 slot="start" id="parameter-dialog-title" className="forge-typography--title">
                {!isEditing ? t('title') : t('edit_title')}
              </h1>
              <ForgeIconButton slot="end">
                <button
                  className="tyler-icons"
                  onClick={this.onClose}
                  data-testid="parameters-editor-modal-header-close-button"
                >
                  <ForgeIcon name="close" />
                </button>
              </ForgeIconButton>
            </ForgeToolbar>
            <div slot="body" className="forge-dialog-container">
              <div className="forge-dialog-body__padding">
                <ForgeTextField invalid={this.state.errors.apiFieldName}>
                  <input
                    type="text"
                    id="input-text-API-field-name"
                    data-testid="input-text-API-field-name"
                    disabled={isEditing}
                    defaultValue={this.state.apiFieldName}
                    onChange={(e) => this.onFieldNameChange(e)}
                    autoComplete="off"
                  />
                  <label htmlFor="input-text-API-field-name" slot="label">
                    {t('api')}
                  </label>
                  {this.state.errors.apiFieldName ? (
                    <span slot="helper-text">{this.state.errors.apiFieldNameErrorMessage}</span>
                  ) : null}
                </ForgeTextField>

                <ForgeTextField invalid={this.state.errors.displayName}>
                  <input
                    type="text"
                    id="input-text-display-name"
                    data-testid="input-text-display-name"
                    defaultValue={this.state.displayName}
                    onChange={(e) => this.setState({ displayName: e.target.value })}
                    autoComplete="off"
                  />
                  <label htmlFor="input-text-display-name" slot="label">
                    {t('display')}
                  </label>
                  {this.state.errors.displayName ? (
                    <span slot="helper-text">{this.state.errors.displayNameErrorMessage}</span>
                  ) : null}
                </ForgeTextField>

                <ForgeSelect
                  id="input-datatype-select"
                  data-testid="input-datatype-select"
                  label={t('type')}
                  value={this.state.type}
                  popupClasses={'datatype-popup'}
                  disabled={isEditing}
                  className="parameter-dropdowns"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.onDataTypeChange(e)}
                >
                  <ForgeOption value="text">{u('text')}</ForgeOption>
                  <ForgeOption value="number">{u('number')}</ForgeOption>
                  <ForgeOption value="calendar_date">{u('calendar_date')}</ForgeOption>
                  <ForgeOption value="checkbox">{u('checkbox')}</ForgeOption>
                  {this.state.errors.displayName ? (
                    <span slot="helper-text">{this.state.errors.displayNameErrorMessage}</span>
                  ) : null}
                </ForgeSelect>

                {enableParametersEnhancement && this.state.type !== 'checkbox' && (
                  <ForgeSelect
                    id="input-suggested-values-select"
                    data-testid="input-suggested-values-select"
                    label={t('available_values')}
                    value={this.state.suggestedValuesType}
                    popupClasses={'datatype-popup'}
                    disabled={isEditing}
                    className="parameter-dropdowns"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.onSuggestedValuesTypeChange(e)}
                  >
                    <ForgeOption value={SuggestedValuesType.ANY_VALUES}>{getSuggestedValuesTranslation(SuggestedValuesType.ANY_VALUES)}</ForgeOption>
                    <ForgeOption value={SuggestedValuesType.SPECIFIC_VALUES}>
                      {getSuggestedValuesTranslation(SuggestedValuesType.SPECIFIC_VALUES)}
                    </ForgeOption>
                    {/* Values from column is implemented on another ticket */}
                    {/* <ForgeOption value={SuggestedValuesType.VALUES_FROM_COLUMN}>{getSuggestedValuesTranslation(SuggestedValuesType.VALUES_FROM_COLUMN)}</ForgeOption> */}
                  </ForgeSelect>
                )}

                {this.state.suggestedValuesType === SuggestedValuesType.SPECIFIC_VALUES &&
                  this.getSpecificValues()}

                {this.state.suggestedValuesType !== SuggestedValuesType.SPECIFIC_VALUES &&
                  this.getDefaultValueInput()}
              </div>
              <div className="parameter_modal_right_panel">
                <ForgeCard className="preview" outlined id={'parameter-dialog-soql-card'}>
                  <ForgeScaffold>
                    <div slot="header" className="forge-card-header-container">
                      <h3 className="forge-typography--subtitle1">Preview</h3>
                    </div>
                    <div className="parameter-modal-soql-body" slot="body">
                      {this.getPreview()}
                    </div>
                  </ForgeScaffold>
                </ForgeCard>
                <ForgeCard outlined id={'parameter-dialog-soql-card'}>
                  <ForgeScaffold>
                    <div slot="header" className="forge-card-header-container">
                      <h3 className="forge-typography--subtitle1">{t('soql_usage_title')}</h3>
                    </div>
                    <div className="parameter-modal-soql-body" slot="body">
                      <p className="forge-typography--body2">{t('soql_description')}</p>
                    </div>
                    <div slot="footer" className="forge-card-footer">
                      <ForgeTextField>
                        <input
                          type="text"
                          className="parameter-modal-soql-text"
                          data-testid="parameter-modal-soql-text-input"
                          disabled
                          value={codeSnippet}
                          aria-label={t('soql_snippet')}
                        />
                        <CopyToClipboard text={codeSnippet} onCopy={this.copyCodeSnippet}>
                          <ForgeIconButton>
                            <button
                              type="button"
                              slot="trailing"
                              aria-label={t('save_to_clipbaord')}
                              onClick={this.copyCodeSnippet}
                              className="tyler-icons"
                            >
                              <ForgeIcon name="content_copy" />
                              <ForgeTooltip id="parameter-modal-soql-usage-tooltip" position={'bottom'}>
                                {t('copy')}
                              </ForgeTooltip>
                            </button>
                          </ForgeIconButton>
                        </CopyToClipboard>
                      </ForgeTextField>
                    </div>
                  </ForgeScaffold>
                </ForgeCard>
              </div>
            </div>
            <div slot="footer" className="footer">
              <ForgeToolbar inverted={true} className="footer-toolbar">
                <div slot="end">
                  <ForgeButton type="outlined">
                    <button
                      id="cancel-button"
                      data-testid="parameters-editor-modal-cancel-button"
                      onClick={this.onClose}
                    >
                      {t('discard_changes')}
                    </button>
                  </ForgeButton>
                  <ForgeButton type="raised">
                    <button id="accept-button" onClick={this.onSave} disabled={!!disableSave}>
                      {t('apply_changes')}
                    </button>
                  </ForgeButton>
                </div>
              </ForgeToolbar>
            </div>
          </ForgeScaffold>
        </div>
      </ForgeDialog>
    );
  }
}

const mapStateToProps = (state: AppState): StateProps => {
  return {
    isOpen:
      state.openModal.type == OpenModalType.NEW_PARAMETER ||
      state.openModal.type == OpenModalType.EDIT_PARAMETER,
    isEditing: state.openModal.type == OpenModalType.EDIT_PARAMETER,
    viewId: state.view.id,
    publishedViewId: option(state.view.publishedViewUid),
    parameters: state.clientContextInfo.variables,
    parameterToEdit: state.openModal.modalData.nonEmpty
      ? some(state.openModal.modalData.get.parameterToEdit)
      : none,
    contextualEventHandlers: state.contextualEventHandlers
  };
};

const mapDispatchToProps = (dispatch: Dispatcher): DispatchProps => {
  return {
    closeModal: () => {
      dispatch(Actions.closeModal());
    },
    onApplyChanges: () => {
      dispatch(Actions.closeModal());
      dispatch(Actions.showToast(t('save_toast'), none));
    },
    onCodeSave: () => {
      dispatch(Actions.showToast(t('save_code_toast'), none));
    },
    dispatch: dispatch
  };
};

const mergeProps = (stateProps: StateProps, dispatchProps: DispatchProps) => {
  return {
    ...stateProps,
    closeModal: dispatchProps.closeModal,
    onApplyChanges: dispatchProps.onApplyChanges,
    onCodeSave: dispatchProps.onCodeSave,
    addParameter: (
      viewId: string,
      parameter: ClientContextVariableCreate,
      onSuccess: () => void,
      onError: (err: any) => void
    ) => {
      dispatchProps.dispatch(
        stateProps.contextualEventHandlers.addParameter(viewId, parameter, onSuccess, onError)
      );
    },
    editParameter: (
      viewId: string,
      parameter: ClientContextVariableCreate,
      onSuccess: () => void,
      onError: (err: any) => void
    ) => {
      dispatchProps.dispatch(
        stateProps.contextualEventHandlers.editParameter(viewId, parameter, onSuccess, onError)
      );
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(ParametersEditorModal);
