import React from 'react';
import _ from 'lodash';
import { Option, some } from 'ts-option';
import { BinaryTree, UnAnalyzedAst } from 'common/types/soql';
import SearchInput from 'common/components/SearchInput';
import { fetchTranslation } from 'common/locale';
import { lastInChain, querySuccess } from 'common/explore_grid/lib/selectors';
import { RunAST } from './visualContainer';
import { Query } from '../redux/store';
import { RemoteStatusInfo, selectors as SelectRemoteStatus  } from '../redux/statuses';
import UnappliedChangesModalWrapper, { Reason as UnappliedChangesModalReason } from './UnappliedChangesModalWrapper';

const t = (k: string) => fetchTranslation(k, 'shared.explore_grid.grid_ribbon');

interface SearchViewState {
  searchText: string | null,
  unappliedUpdatesToSearchText: boolean,
  skipDebounce: boolean,
  showUnappliedChangeModal: boolean
  showCompilationFailedModal: boolean
}

interface SearchViewProps {
  ast: Option<BinaryTree<UnAnalyzedAst>>,
  query: Query,
  runAST: RunAST,
  remoteStatusInfo: Option<RemoteStatusInfo>,
  buildDiscardChanges: (lastQueryText: Option<string>) => () => void
}

export default class SearchView extends React.Component<SearchViewProps, SearchViewState> {

  state = {
    searchText: querySuccess(this.props.query.queryResult).map(qr => lastInChain(qr.compiled.unanalyzed).search).getOrElseValue(null),
    unappliedUpdatesToSearchText: false,
    skipDebounce: false,
    showUnappliedChangeModal: false,
    showCompilationFailedModal: false
  };

  // the logic below is enforcing these rules of behavior:
  // 1. When AST compiles for first time on page, we need to set searchText from ast.search
  // 2. When user has hit undo, redo, or edited their queryString directly, then ast.search must override searchText
  // 3. When user has been typing into searchText but we've been delaying compiling/querying, then ast.search cannot override searchText
  componentDidUpdate() {
    const lastRunSearch = querySuccess(this.props.query.queryResult).map(qr => lastInChain(qr.compiled.unanalyzed).search).getOrElseValue(null);
    const lastCompiledSearch = this.props.ast.map(lastInChain).map(ast => ast.search).getOrElseValue(null);
    const {searchText: stateSearch, unappliedUpdatesToSearchText, skipDebounce, showUnappliedChangeModal} = this.state;

    if (!showUnappliedChangeModal && !this.queryOrCompilationInProgress()) {
      // the user's search and the AST are in sync again
      if (unappliedUpdatesToSearchText && stateSearch === lastRunSearch) {
        this.setState({unappliedUpdatesToSearchText: false});
      }

      if (unappliedUpdatesToSearchText && stateSearch !== lastRunSearch && stateSearch !== lastCompiledSearch) {
        // we're not in sync and now that querying is complete, issue the user's update to the AST
        if (skipDebounce) {
          this.setState({skipDebounce: false});
          this.updateOrModal(stateSearch);
        } else {
          this.debouncedUpdateQuery(stateSearch);
        }
        // if the searchText matches lastCompiledSearch then we are just in progress compiling + running their search
      } else if (stateSearch !== lastRunSearch && stateSearch !== lastCompiledSearch) {
        // the user hasn't been typing, so we need to correct searchText state to match AST
        // causes: undo, redo, typing into the soql editor
        this.setState({searchText: lastRunSearch});
      }
    }
  }

  queryOrCompilationInProgress = () => {
    return this.props.ast.isEmpty || SelectRemoteStatus.inProgress(this.props.remoteStatusInfo).nonEmpty;
  };

  updateOrModal = (value: string | null) => {
    const shouldOpenModal = SelectRemoteStatus.applyable(this.props.remoteStatusInfo).isDefined;
    if (shouldOpenModal) {
      this.setState({showUnappliedChangeModal: true});
    } else {
      this.runUpdate(value);
    }
  };

  runUpdate = (value: string | null) => {
    this.props.ast.map(lastInChain).map(ast => (
        {
          ...ast,
          search: value === '' ? null : value
        }
      )).forEach(ast => this.props.runAST(ast, some(1)));
  };

  debouncedUpdateQuery = _.debounce((s: string | null) => {
    this.updateOrModal(s);
  }, 500);


  onChange = (value: string) => {
    const search = value === '' ? null : value;
    this.setState({
      searchText: search,
      unappliedUpdatesToSearchText: true,
      showCompilationFailedModal: SelectRemoteStatus.cannotRunQuery(this.props.remoteStatusInfo).isDefined
    });
  };

  discardSearchAndDismiss = () => {
    // fall back to ast from the last run query-- there's none if the text failed to compile
    const astOpt = this.props.ast.isEmpty ?
      querySuccess(this.props.query.queryResult).map(qr => lastInChain(qr.compiled.unanalyzed)) :
      this.props.ast.map(lastInChain);
    this.setState({
      searchText: astOpt.map(ast => ast.search).getOrElseValue(null),
      showUnappliedChangeModal: false,
      showCompilationFailedModal: false
    });
  };

  onDiscardChanges = () => {
    const lastQueryText = querySuccess(this.props.query.queryResult).flatMap((qr) => qr.compiled.text);
    const discardChanges = this.props.buildDiscardChanges(lastQueryText);
    this.setState({showUnappliedChangeModal: false, showCompilationFailedModal: false});
    discardChanges();
  };

  applyChangesAndSearch = () => {
    this.runUpdate(this.state.searchText);
    this.setState({showUnappliedChangeModal: false, showCompilationFailedModal: false});
  };

  render() {
    const {searchText, showUnappliedChangeModal, showCompilationFailedModal} = this.state;
    return (
      <>
        <SearchInput
          onSearch={(value) => {
            this.onChange(value);
            this.setState({skipDebounce: true});
          }}
          onChange={this.onChange}
          value={searchText || ''}
          title={t('search')}
          id={'search-view'}
        />
        {showUnappliedChangeModal && <UnappliedChangesModalWrapper
          onPrimaryAction={this.applyChangesAndSearch}
          onDiscardChanges={this.onDiscardChanges}
          onDismiss={this.discardSearchAndDismiss}
          isOpen={showUnappliedChangeModal}
          reason={UnappliedChangesModalReason.SEARCH}/>}
        {showCompilationFailedModal && <UnappliedChangesModalWrapper
          onPrimaryAction={this.discardSearchAndDismiss}
          onDiscardChanges={this.onDiscardChanges}
          onDismiss={this.discardSearchAndDismiss}
          isOpen={showCompilationFailedModal}
          reason={UnappliedChangesModalReason.INVALID_SOQL}/>}
      </>
    );
  }
}
