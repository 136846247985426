import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { UnAnalyzedAst, BinaryTree, TypedSelect } from 'common/types/soql';
import { AppState } from '../redux/store';
import I18n from 'common/i18n';
import { Tab } from 'common/explore_grid/types';
import { Option, none, some } from 'ts-option';
import { getAstFromAnalysis, getUnAnalyzedAst, lastInChain, replaceLastInChain } from '../lib/selectors';
import { Dispatcher, compileAndRunAst } from '../redux/actions';
import { ForgeButton } from '@tylertech/forge-react';
import { ForgeIcon } from '@tylertech/forge-react';
import { Either, factorOption } from 'common/either';
import { whichAnalyzer } from '../lib/feature-flag-helpers';

const t = (k: string) => I18n.t(k, { scope: 'shared.explore_grid.grid_datasource.clear_all' });

interface StateProps {
  maybeAstChain: Option<Either<BinaryTree<UnAnalyzedAst>, BinaryTree<TypedSelect>>>;
  fourfour: string;
}

interface DispatchProps {
  compileAndRunNewAst: (fourfour: string, newChain: Either<BinaryTree<UnAnalyzedAst>, BinaryTree<TypedSelect>>) => void
}

interface ExternalProps {
  tab: Tab;
  clearEnabled: boolean;
  getClearedAST: (ast: Either<UnAnalyzedAst, TypedSelect>) => Either<UnAnalyzedAst, TypedSelect>;
}

export type ClearExprProps = StateProps & DispatchProps & ExternalProps;

interface ClearExprState {
  savedAst: Option<Either<UnAnalyzedAst, TypedSelect>>;
}

export class ClearExpr extends React.Component<ClearExprProps, ClearExprState> {
  state = {
    savedAst: none
  };

  componentDidUpdate(prevProps: ClearExprProps) {
    const { clearEnabled } = this.props;
    const hideUndo = clearEnabled && !prevProps.clearEnabled;
    if (hideUndo && this.state.savedAst.isDefined) {
      this.setState({ savedAst: none });
    }
  }

  onClearAll = () => {
    const { maybeAstChain, getClearedAST, compileAndRunNewAst, fourfour } = this.props;
    const maybeAst = maybeAstChain.map(c => c.mapBoth(lastInChain, lastInChain));
    this.setState({ savedAst: maybeAst });
    maybeAstChain.forEach(chainEither => {
      maybeAst.match({
        none: _.noop,
        some: (astEither: Either<UnAnalyzedAst, TypedSelect>) => {
          const newChain = chainEither.mapBoth(
            chain => replaceLastInChain(chain, getClearedAST(astEither).left),
            chain => replaceLastInChain(chain, getClearedAST(astEither).right)
          );
          compileAndRunNewAst(fourfour, newChain);
        }
      });
    });
  };

  onUndoClear = () => {
    const { maybeAstChain, compileAndRunNewAst, fourfour  } = this.props;
    maybeAstChain.forEach(chain => {
      this.state.savedAst.match({
        none: _.noop,
        some: (astEither: Either<UnAnalyzedAst, TypedSelect>) => {
          const newChain = astEither.mapBoth(
            ast => replaceLastInChain(chain.left, { ...ast }),
            ast => replaceLastInChain(chain.right, { ...ast })
          );
          compileAndRunNewAst(fourfour, newChain);
        }
      });
    });
    this.setState({ savedAst: none });
  };

  render() {
    const { tab, clearEnabled } = this.props;
    const showUndo = this.state.savedAst.isDefined;
    return (<span className="datasource-clear-all-expr datasource-left-subtitle most-left-subtitle">
      <span className="bar">|</span>
      <ForgeButton>
        <button
          disabled={!clearEnabled}
          className={tab}
          onClick={this.onClearAll}>
          <ForgeIcon name="close_circle" />
          <span className="clear-btn-text">{t('clear')}</span>
        </button>
      </ForgeButton>

      {showUndo &&
        <ForgeButton>
          <button
            className="clear-all-undo"
            onClick={this.onUndoClear}>
            <ForgeIcon name="undo" />
            {t('undo')}
          </button>
        </ForgeButton>}
    </span>);
  }
}

const mapStateToProps = (state: AppState, ownProps: ExternalProps): StateProps & ExternalProps => {
  return {
    ...ownProps,
    maybeAstChain: factorOption(whichAnalyzer(getUnAnalyzedAst, getAstFromAnalysis)(state.query) as Either<Option<BinaryTree<UnAnalyzedAst>>, Option<BinaryTree<TypedSelect>>>),
    fourfour: state.fourfour
  };
};

const mapDispatchToProps = (dispatch: Dispatcher, props: ExternalProps) => {
  return {
    compileAndRunNewAst: (fourfour: string, newChainEither: Either<BinaryTree<UnAnalyzedAst>, BinaryTree<TypedSelect>>) =>
      newChainEither.foldEither(newChain => dispatch(compileAndRunAst(fourfour, newChain, none, some(props.tab))))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ClearExpr);
