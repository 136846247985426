import React from 'react';
import { fetchTranslation } from 'common/locale';
import UnappliedChangesModal, {UnappliedChangesModalProps, buildNavigationOptions as buildNavOptions, NavigateWithUnappliedChanges as NavWithUnappliedChanges} from 'common/components/UnappliedChangesDialog/UnappliedChangesModal';

const t = (k: string) => fetchTranslation(k, 'shared.explore_grid.unapplied_changes_modal');

// Optional reason to override the default messaging and title
export enum Reason {
  TAB = 'tab',
  UNDO = 'undo',
  REDO = 'redo',
  INVALID_SOQL = 'invalid_soql',
  COMPILING_QUERY = 'compiling_query',
  SEARCH = 'search',
  DISCARD_PARAM_OVERRIDE = 'discard_param_overrides'
}

interface Props {
  isOpen: boolean;
  onPrimaryAction: () => void;
  onDiscardChanges: () => void;
  onDismiss: () => void;
  reason?: Reason // leave undefined for default behavior
}
// re-exporting these functions/types here to make it easier to just import what you need from the wrapper
export const buildNavigationOptions = buildNavOptions;
export type NavigateWithUnappliedChanges = NavWithUnappliedChanges;

const reasonToButtonType = (reason: Reason | undefined) => {
    switch (reason) {
      case undefined:
      case Reason.SEARCH: return 'raised';
      case Reason.TAB: return 'raised';
      default: return 'outlined';
    }
  };

/**
 * UnappliedChangesModal originally built to be EC specific. Later it was refactored to be more generic. This wrapper was made to prevent the need to refactor all
 * the places that used to the old version. Specifically this takes a reason and uses that to figure out the correct translations for the title and body text, along with button stylings.
 * @param props
 * @returns UnappliedChangesModal
 */
export default function UnappliedChangesModalWrapper(props: Props) {
  const { isOpen, onPrimaryAction, onDiscardChanges, onDismiss, reason } = props;

  const modalProps: UnappliedChangesModalProps = {
    isOpen,
    onPrimaryAction,
    onDiscardChanges,
    onDismiss,
    title: reason ? t('title_on_' + reason) : undefined,
    bodyText: reason ? t('body_on_' + reason) : undefined,
    affirmativeButtonText: reason ? t('apply_changes_on_' + reason) : undefined,
    affirmativeButtonType: reasonToButtonType(reason)
  };

  return (
    <UnappliedChangesModal {...modalProps} />
  );
}
