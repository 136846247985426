import Button, { VARIANTS } from 'common/components/Button';
import { Modal, ModalContent, ModalFooter, ModalHeader } from 'common/components/Modal';
import { showErrorToastNow } from 'common/components/ToastNotification/Toastmaster';
import I18n from 'common/i18n';
import { soqlRendering } from 'common/types/soql';
import { View } from 'common/types/view';
import { createNewChildDraft } from 'common/views/helpers';
import { assign } from 'common/window_location';
import { querySuccess } from '../lib/selectors';
import { Dispatcher } from '../redux/actions';
import { AppState, VQEColumn } from '../redux/store';
import React from 'react';
import { connect } from 'react-redux';
import { Option } from 'ts-option';
import * as Actions from '../redux/actions';
import '../styles/save-discard-cancel-modal.scss';

const t = (k: string, options: { [key: string]: any } = {}) =>
  I18n.t(k, { scope: 'shared.explore_grid.save_discard_cancel_modal', ...options });

// exported for testing
export interface SaveDiscardCancelModalState {
  // disables modal buttons when busy saving
  busy: boolean;
  // the new value being entered
  newViewName: string;
  // the intercepted address of the clicked link
  targetAddress: string;
  showSaveViewModal: boolean;
}

interface DispatchProps {
  saveView: (view: View, queryText: string, onSuccess: () => void, onFailure: () => void) => void;
}

type SaveDiscardCancelModalProps = {
  defaultQuery: string;
  view: View;
  newQueryString: Option<string>;
  editing: boolean;
  columns: VQEColumn[];
};

export type SaveDiscardCancelModalCombinedProps = DispatchProps & SaveDiscardCancelModalProps;

export class SaveDiscardCancelModal extends React.Component<
  SaveDiscardCancelModalCombinedProps,
  SaveDiscardCancelModalState
> {
  state = {
    busy: false,
    newViewName: '',
    targetAddress: '',
    showSaveViewModal: false
  };

  componentDidMount() {
    const linksList = document.links;
    for (let i = 0; i < linksList.length; i++) {
      linksList[i].addEventListener('click', this.onClick.bind(this));
    }
  }

  componentWillUnmount() {
    const linksList = document.links;
    for (let i = 0; i < linksList.length; i++) {
      linksList[i].removeEventListener('click', this.onClick.bind(this));
    }
  }

  onClick = (e: React.SyntheticEvent<HTMLAnchorElement>) => {
    // EN-48690: Doesn't make sense to ask non-logged-in users to save when they can't.
    if (!window.socrata.currentUser) {
      return;
    }

    let address = '';
    if (e) {
      e.preventDefault();
      address = e.currentTarget.href;
    }
    const { defaultQuery, newQueryString, view } = this.props;

    let unsavedQuery = false;
    if (this.props.editing) {
      unsavedQuery = newQueryString.map((changed) => changed !== view.queryString).getOrElseValue(false);
    } else {
      unsavedQuery = newQueryString.map((query) => query !== defaultQuery).getOrElseValue(false);
    }

    if (unsavedQuery) {
      this.setState({ showSaveViewModal: true, targetAddress: address });
    } else {
      assign(address);
    }
  };

  onSave = (e: React.SyntheticEvent<HTMLFormElement | HTMLButtonElement>) => {
    if (this.state.newViewName.length === 0 && this.props.editing === false) return;

    this.setState({ busy: true });
    this.props.newQueryString.forEach(async (queryString) => {
      if (this.props.editing) this.saveView(queryString);
      else this.createView(queryString);
    });
  };

  createView = async (queryString: string) => {
    try {
      const redirectPath = await createNewChildDraft(
        // until we have to deal with column metadata, let's let core handle creating the columns to go with our query
        {...this.props.view, columns: this.props.columns},
        queryString,
        this.state.newViewName
      );

      // return the user to the page to retry the navigation that initiated this fork
      assign(redirectPath);
    } catch (error) {
      console.error(error);
      showErrorToastNow(I18n.t('shared.components.asset_action_bar.create_view_failed'));
      // Only clear the busy state if there's an error so the user can try again
      // If creation succeeds, keep the busy state so the user doesn't click things
      // while the new page is loading.
      this.setState({ busy: false });
    }
  };

  saveView = (queryString: string) => {
    const onSuccess = () => this.setState({ showSaveViewModal: false, busy: false });
    const onFailure = () => this.setState({ busy: false });
    this.props.saveView(this.props.view, queryString, onSuccess, onFailure);
  };

  onDiscardChanges = () => {
    assign(this.state.targetAddress);
  };

  onChange = (newViewName: string) => {
    this.setState({ newViewName });
  };

  onDismiss = () => {
    this.setState({ showSaveViewModal: false });
  };

  renderContent = (newViewName: string) => {
    if (this.props.editing) {
      return (
        <div className="input-container">
          <div className="modal-message-container">{t('description')}</div>
        </div>
      );
    } else {
      return (
        <form onSubmit={(e) => this.onSave(e)}>
          <div className="input-container">
            <div className="modal-message-container">{t('description_new_view')}</div>
            <div className="caption">{t('name_view')}</div>
            <input
              type="text"
              value={newViewName}
              onChange={(e) => this.onChange(e.target.value)}
              className="text-input"
            />
            <div className="caption">{t('based_on', { name: this.props.view.name })}</div>
          </div>
        </form>
      );
    }
  };

  render() {
    const { busy, newViewName } = this.state;
    const disableSave = busy || (newViewName.length === 0 && this.props.editing === false);

    if (this.state.showSaveViewModal) {
      return (
        <Modal onDismiss={this.onDismiss} dismissOnOutsideClick={false} className="save-discard-cancel-modal">
          <ModalHeader showCloseButton onDismiss={this.onDismiss} />
          <ModalContent>
            <h1>{t('unsaved_changes')}</h1>
            {this.renderContent(newViewName)}
          </ModalContent>
          <ModalFooter>
            <div className="modal-footer">
              <Button variant={VARIANTS.DEFAULT} onClick={this.onDismiss} disabled={busy}>
                {t('cancel')}
              </Button>
              <Button
                variant={VARIANTS.ERROR}
                onClick={this.onDiscardChanges}
                className="modal-error-button"
                disabled={busy}
              >
                {t('discard_changes')}
              </Button>
              <Button
                variant={VARIANTS.PRIMARY}
                onClick={(e) => this.onSave(e)}
                disabled={disableSave}
                busy={busy}
              >
                {t('save')}
              </Button>
            </div>
          </ModalFooter>
        </Modal>
      );
    }
    return null;
  }
}

const mapStateToProps = (state: AppState): SaveDiscardCancelModalProps => {
  const { view, editing } = state;
  const newQueryString = querySuccess(state.query.queryResult).map((result) =>
    soqlRendering.unwrap(result.compiled.rendering)
  );

  return {
    view,
    newQueryString,
    defaultQuery: state.saveInfo.defaultQuery,
    editing,
    columns: state.columns
  };
};

const mapDispatchToProps = (dispatch: Dispatcher): DispatchProps => {
  return {
    saveView: (view: View, queryText: string, onSuccess: () => void, onFailure: () => void) => {
      dispatch(Actions.saveViewQueryString(view, queryText, onSuccess, onFailure));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SaveDiscardCancelModal);
