import React, { MouseEventHandler } from 'react';
import cx from 'classnames';
import { ForgeIcon, ForgeListItem, ForgeTooltip } from '@tylertech/forge-react';

import { fetchTranslation } from 'common/locale';
import { Tab } from 'common/explore_grid/types';
import UnappliedChangesModalWrapper, {
  Reason as UnappliedChangesModalReason
} from '../UnappliedChangesModalWrapper';
import { none, some, Option } from 'ts-option';

const t = (k: string) => fetchTranslation(k, 'shared.explore_grid.grid_datasource');

const iconFor = (tab: Tab): JSX.Element => {
  const label = t(tab);
  switch (tab) {
    case Tab.ColumnManager:
      return createIcon('view_column', label);
    case Tab.Filter:
      return createIcon('filter', label);
    case Tab.Aggregate:
      return createIcon('group', label);
    case Tab.Join:
      return createIcon('set_none', label);
    case Tab.Code:
      return createIcon('code', label);
    case Tab.Parameters:
      return createIcon('variable', label);
    case Tab.Search:
      return createIcon('search', label);
    default:
      return createIcon('head_question_outline', label);
  }
};

const createIcon = (iconName: string, label: string): JSX.Element => {
  return <ForgeIcon className='grid-datasource-tab-icon-forge' name={iconName} aria-label={label} />;
};

export interface Props {
  className?: string;
  discardChanges: () => void;
  updateTab: (toTab: Tab) => void;
  onClick: (
    toTab: Tab,
    openUnappliedChangesModal: (reason: UnappliedChangesModalReason) => void,
    discardChanages: () => void
  ) => MouseEventHandler<HTMLAnchorElement>;
  onApplyQuery: () => void;
  selectedClass: (toTab: Tab) => string;
  toTab: Tab;
  targetWindow?: Window;
}

export interface State {
  modalOpenReason: Option<UnappliedChangesModalReason>;
}

export default class TabLink extends React.Component<Props, State> {
  state = { modalOpenReason: none };
  showUnappliedChangesModal = (reason: UnappliedChangesModalReason) => {
    this.setState({ modalOpenReason: some(reason) });
  };

  hideUnappliedChangesModal = () => {
    this.setState({ modalOpenReason: none });
  };

  onDiscardChanges = () => {
    const { discardChanges, updateTab, toTab } = this.props;
    this.hideUnappliedChangesModal();
    discardChanges();
    updateTab(toTab);
  };

  onApplyChanges = () => {
    this.hideUnappliedChangesModal();
    this.props.onApplyQuery();
  };

  render() {
    const { className, onClick, selectedClass, toTab } = this.props;
    const { modalOpenReason } = this.state;
    const name = t(toTab);
    const classNames = cx('grid-datasource-tab-item tab-link', className, selectedClass(toTab));
    return (
      <>
        <ForgeListItem
          className={classNames}
          onClick={onClick(toTab, this.showUnappliedChangesModal, this.onDiscardChanges)}
          role="tab"
          selected={selectedClass(toTab) === 'current'}
          aria-controls="grid-datasource-tabpanel"
          data-testid={`ec-tab-link-${toTab}`}
        >
          {iconFor(toTab)}
          {modalOpenReason.nonEmpty && (
            <UnappliedChangesModalWrapper
              onPrimaryAction={this.onApplyChanges}
              onDiscardChanges={this.onDiscardChanges}
              onDismiss={this.hideUnappliedChangesModal}
              isOpen={modalOpenReason.nonEmpty}
              reason={modalOpenReason.get}
            />
          )}
        </ForgeListItem>
        <ForgeTooltip>{name}</ForgeTooltip>
      </>
    );
  }
}
