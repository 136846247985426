import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { Modal, ModalHeader, ModalFooter, ModalContent } from 'common/components/Modal';
import Button, { VARIANTS } from 'common/components/Button';
import I18n from 'common/i18n';
import { AnalyzedSelectedExpression } from 'common/types/soql';
import { AppState } from '../redux/store';
import { currentUserIsLoggedIn } from 'common/current_user';
import { FeatureFlags } from 'common/feature_flags';
import Flyout from 'common/components/Flyout';
import SoQLTypeIcon from 'common/components/SoQLTypeIcon';
import { Tab } from 'common/explore_grid/types';
import { SelectionItem } from '../lib/soql-helpers';
import { Either } from 'common/either';
const t = (k: string, options = {}) => I18n.t(k, { scope: 'shared.explore_grid.calculated_column_modal', ...options });

interface ExternalProps {
  excludedColumns: AnalyzedSelectedExpression[] | SelectionItem[];
  onApply: () => void;
  onDismiss: () => void;
  tab: Tab
}

interface StateProps {
  editing: boolean;
}

type CalculatedColModalProps = ExternalProps & StateProps;

const columnName = (c: AnalyzedSelectedExpression | SelectionItem) => {
  if ('name' in c) {
    return c.name;
  } else {
    return c.schemaEntry.name;
  }
};

export function CalculatedColModal({ onApply, onDismiss, excludedColumns, tab, editing }: CalculatedColModalProps) {
  const shownColumns = excludedColumns.length <= 3 ? excludedColumns : excludedColumns.slice(0, 2);

  // do not render the soql reference if:
  //   * hide_soql_tab feature flag is set to true OR
  //   * the user is unauthenticated
  const renderSoQL = !FeatureFlags.value('hide_soql_tab') && currentUserIsLoggedIn();

  let mainContent = t(
    `${editing ? 'explanation_draft' : 'explanation_explore'}.${tab}`,
    { count: excludedColumns.length }
  );
  if (renderSoQL) mainContent += ' ' + t('withSoQLref', { count: excludedColumns.length });

  return excludedColumns.length > 0 ? (<Modal
    onDismiss={onDismiss}
    dismissOnOutsideClick={false}
    className="calc-col-modal"
  >
    <ModalHeader showCloseButton onDismiss={onDismiss}>
      <h1 className="h5 modal-header-title">
        <span className="socrata-icon-warning"></span>
        <span className="header-text">{t(`modal_header.${tab}`)}</span>
      </h1>
    </ModalHeader>
    <ModalContent>
      <div>{mainContent}</div>
      <ul className="excluded-col-list">
        {shownColumns.map(c => <li key={columnName(c)}>
          <div>
            <SoQLTypeIcon type={c.expr.soql_type} />
            <span className="column-name">{columnName(c)}</span>
          </div>
        </li>)}
        {excludedColumns.length > 3 && <li>{t('more_columns', { count: excludedColumns.length - 2})}</li>}
      </ul>
      <div>{t('are_you_sure', { count: excludedColumns.length })}</div>
      <div className="calc-cols-help">
        {t('how_are_calc_cols_different')}
        <Flyout text={t('flyout_text')}>
          <span className="socrata-icon-info"></span>
        </Flyout>
      </div>
    </ModalContent>
    <ModalFooter>
        {tab === Tab.ColumnManager ? (
          <div className="modal-footer">
            <Button variant={VARIANTS.ERROR} onClick={onApply}>{t('delete')}</Button>
            <Button variant={VARIANTS.PRIMARY} onClick={onDismiss}>{t('keep')}</Button>
          </div>
        ) : (
          <div className="modal-footer">
            <Button variant={VARIANTS.DEFAULT} onClick={onDismiss}>{t('cancel')}</Button>
            <Button variant={VARIANTS.ERROR} onClick={onApply}>{t('delete_cols', { count: excludedColumns.length })}</Button>
          </div>
        )}
    </ModalFooter>
  </Modal>) : null;
}

const mapStateToProps = (state: AppState) => {
  return {
    editing: state.editing
  };
};

export default connect(mapStateToProps)(CalculatedColModal);
