import { VQEColumn } from 'common/explore_grid/redux/store';
import { AnalyzedSelectedExpression, TableQualifier, TypedOrderBy, UnAnalyzedAst, UnAnalyzedSelectedExpression } from 'common/types/soql';
import { SelectionItem, SelectedColumn, CalculatedColumn } from '../../lib/soql-helpers';
import { ViewColumn } from 'common/types/viewColumn';
import { Option, some, none } from 'ts-option';
import { ViewColumnColumnRef } from '../../lib/selectors';

export interface ViewColumnSortData {
  orderBy: Option<TypedOrderBy>;
  sortIndex: number;
}

export interface ProjectableTerm {
  expr: Option<AnalyzedSelectedExpression>;
  unAnalyzedExpr: Option<UnAnalyzedSelectedExpression>;
  viewColumn: Option<ViewColumnColumnRef['column']>;
  metadataColumn: Option<VQEColumn>;
  typedRef: Option<ViewColumnColumnRef['typedRef']>;
  ref: Option<ViewColumnColumnRef['ref']>;
  view: Option<ViewColumnColumnRef['view']>;
  projectionIndex: number;
  displayIndex: number;
  sortData: ViewColumnSortData;
}

export type ProjectableTermIdentifier = SelectionIdentifier | ColumnRefIdentifier;

export interface SelectionIdentifier {
  type: 'selection_identifier',
  selectionName: string;
}

export interface ColumnRefIdentifier {
  type: 'column_ref_identifier',
  qualifier: TableQualifier;
  value: string;
}

export const isColumnRefIdentifier = (x: ProjectableTermIdentifier): x is ColumnRefIdentifier => {
  return x.type === 'column_ref_identifier';
};

export const isSelectionIdentifier = (x: ProjectableTermIdentifier): x is SelectionIdentifier => {
  return x.type === 'selection_identifier';
};

export const keyForProjectableTerm = (projectableTerm: ProjectableTerm): string => projectableTerm.expr.match({
  some: expr => expr.name,
  none: () => {
    const { viewColumn, view } = projectableTerm;
    return [view.get.id, viewColumn.get.id].join('_');
  }
});


export const astFromProjectableTerms = (terms: ProjectableTermIdentifier[], columns: ProjectableTerm[], ast: UnAnalyzedAst): UnAnalyzedAst => {
  const selectionExprs = terms.flatMap(id => {
    const projectableTerm = columns.find(col => {
      if (isColumnRefIdentifier(id) && col.ref.nonEmpty) {
        return col.ref.get.value === id.value && col.ref.get.qualifier === id.qualifier;
      } else if (isSelectionIdentifier(id) && col.unAnalyzedExpr.nonEmpty) {
        return col.expr.get.name === id.selectionName;
      }
      return false;
    });

    return projectableTerm || [];
  }).flatMap((projectableTerm: ProjectableTerm) => {
    return projectableTerm.unAnalyzedExpr.map(expr => [expr]).getOrElseValue([]);
  });

  const selection = {
    all_system_except: null,
    all_user_except: [],
    exprs: selectionExprs
  };

  return {
    ...ast,
    selection: selection
  };
};

export const selectionItemFromProjectableTerm = (term: ProjectableTerm): SelectionItem => {
  const provenance = term.view.isDefined && term.viewColumn.isDefined
    ? some({ view: term.view.get, column: term.viewColumn.get })
    : none;

  if (provenance.isDefined) {
    return {
      provenance,
      expr: term.typedRef.get,
      schemaEntry: {
        name: term.expr.get.name,
        type: term.typedRef.get.soql_type,
        is_synthetic: false
      }
    } as SelectedColumn;
  } else {
    const { expr, name } = term.expr.get;
    const type = expr.soql_type;
    return {
      provenance,
      expr,
      schemaEntry: {
        name,
        type,
        is_synthetic: false
      }
    } as CalculatedColumn;
  }
};
