import React from 'react';
import { ProjectableTerm } from './common';
import I18n from 'common/i18n';

const t = (k: string, options = {}) => I18n.t(k, { ...options, scope: 'shared.explore_grid.visual_column_manager.headers' });

interface Props {
  projectableTerm: ProjectableTerm;
  orderByCount: number;
  reorderOrderBy: (sortIndex: number, newSortIndex: number) => void;
}

class SortIndex extends React.Component<Props> {
  render() {
    const { sortIndex } = this.props.projectableTerm.sortData;
    return (<input
      aria-label={t('sort_order')}
      type="number"
      defaultValue={sortIndex + 1}
      onChange={(evt) => this.props.reorderOrderBy(sortIndex, parseInt(evt.target.value) - 1)}
      min={Math.max(sortIndex, 1)} max={Math.min(sortIndex + 2, this.props.orderByCount)}
    />);
  }
}

export default SortIndex;
