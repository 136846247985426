import { none, some } from 'ts-option';
import { ViewContext } from 'common/types/compiler';
import { VisualColumnManager } from '../VisualColumnManager';
import { ProjectableTerm } from './common';

function filterByColumnName(this: VisualColumnManager, str: string) {
  const filterString = str.trimStart();

  const currentFilter = this.state.filtering.getOrElseValue({ columnName: '' });
  this.setState({
    filtering:
      filterString.length > 0 || currentFilter.view
        ? some({
            ...currentFilter,
            columnName: filterString
          })
        : none
  });
}

function filterByView(this: VisualColumnManager, viewContext: keyof ViewContext | null) {
  if (viewContext) {
    this.setState({ filtering: some({ columnName: '', view: viewContext }) });
  } else {
    this.setState({ filtering: none });
  }
}

function applyFilterPerTerm(this: VisualColumnManager, term: ProjectableTerm): boolean {
  if (!this.state.filtering.isDefined) {
    return true;
  }

  const currentFilter = this.state.filtering.get;
  const filterStringRegex = new RegExp(currentFilter.columnName, 'i');
  const viewMatchesFilter = this.getViewContexts()
    .map((viewContext) => {
      if (currentFilter.view) {
        return (
          term.view.match({ some: (v) => v.id, none: () => null }) === viewContext[currentFilter.view].id
        );
      } else {
        return true;
      }
    })
    .getOrElseValue(true);

  return (
    viewMatchesFilter &&
    (term.viewColumn.match({
      some: (vc) => filterStringRegex.test(vc.fieldName) || filterStringRegex.test(vc.name || ''),
      none: () => false
    }) ||
      term.expr.match({
        some: (expr) => filterStringRegex.test(expr.name),
        none: () => false
      }))
  );
}

export { filterByColumnName, filterByView, applyFilterPerTerm };
