import React from 'react';
import { connect } from 'react-redux';
import { Option } from 'ts-option';

import { GuidanceSummaryV2 } from 'common/types/approvals';
import { View } from 'common/types/view';
import { AppState, LocationParams } from '../redux/store';

import GridDatasource from './GridDatasource';
import GridPager from './GridPager';
import GridSidebar from './GridSidebar';
import GridTable from './GridTable';
import GridRibbon, { getReturnToLinkType } from './GridRibbon';
import SavedQueryModal from './SavedQueryModal';
import ExploreGridAssetActionBar from './ExploreGridAssetActionBar';
import SaveDiscardCancelModal from './SaveDiscardCancelModal';
// scoped to the whole window. Sets things like the body height, etc.
import '../styles/explore-grid-page.scss';
// scoped to just #explore-grid-app
import '../styles/explore-grid.scss';
import ParametersEditorModal from './ParametersEditorModal';
import ExploreGridForgeToast from './ExploreGridForgeToast';
import { getSiteChromeHeight, getAssetBarHeight } from 'common/util/siteMeasurements';
import GridBanner from './GridBanner';

class App extends React.Component<Props> {
  getHeaderOffset = () => {
    const actionBarHeight = getAssetBarHeight();
    const siteChromeHeaderHeight = getSiteChromeHeight();
    const totalOffSet = siteChromeHeaderHeight + actionBarHeight;
    return { height: `calc(100vh - ${totalOffSet}px)` };
  };

  render() {
    return (
      <div>
        <ExploreGridAssetActionBar/>
        <SaveDiscardCancelModal />
        <div style={this.getHeaderOffset()} className='grid-wrap'>
          <div className="grid-main">
            <GridRibbon returnToLinkType={getReturnToLinkType(this.props.view)}/>
            <GridBanner />
            <GridTable />
            <GridPager />

            <GridDatasource embedded={false} />
          </div>
          <GridSidebar />
          <SavedQueryModal />
          <ParametersEditorModal />
          <ExploreGridForgeToast />
        </div>
      </div>
    );
  }
}
interface Props {
  fourfour: string;
  params: LocationParams;
  view: View;
  queryText: Option<string>;
  approvalsGuidance: GuidanceSummaryV2 | undefined;
}
interface ExternalProps {
  params: LocationParams;
}

const mapStateToProps = (state: AppState, props: ExternalProps): Props => {
  return {
    fourfour: state.fourfour,
    params: props.params,
    view: state.view,
    queryText: state.query.text,
    approvalsGuidance: state.approvalsGuidance
  };
};

export default connect(mapStateToProps)(App);
