import TableCell from 'common/components/DatasetTable/cell/TableCell';
import { SoQLType } from 'common/types/soql';
import * as _ from 'lodash';
import React, { MouseEvent } from 'react';
import { ColumnFormatLookup, DEFAULT_WIDTH } from './ColumnHeader';
import { option } from 'ts-option';

export type SoQLTypeLookup = (fieldName: string) => SoQLType;

interface RowProps {
  data: any;
  uid: string;
  names: string[];
  typeLookup: SoQLTypeLookup;
  formatLookup: ColumnFormatLookup;
  onMouseOver?:(event: MouseEvent<HTMLTableDataCellElement, globalThis.MouseEvent>) => void;
  onMouseOut?:() => void;
}
export function Row(props: RowProps): JSX.Element {
  const cellValue = (value: any) => {
    if (value === false) {
      return value;
    } else {
      return value || null;
    }
  };

  return (
    <tr>
      {props.names.map((fieldName) => {
        // EN-51772: The purpose of this line is in case Core returns FOO when we expect foo, or vice versa.
        const datumKey = option(Object.keys(props.data).find(key => key.toLowerCase() === fieldName.toLowerCase()))
          .getOrElseValue(fieldName);
        return (
          <TableCell
            uid={props.uid}
            key={fieldName}
            cell={{ ok: cellValue(props.data[datumKey]) }}
            type={props.typeLookup(fieldName)}
            isHidden={false}
            isDropping={false}
            isEditing={false}
            failed={false}
            canEdit={false}
            width={'auto'}
            format={props.formatLookup(fieldName)}
            editCell={_.noop}
            updateCell={_.noop}
            onMouseOver={props.onMouseOver}
            onMouseOut={props.onMouseOut}
          />
        );
      })}
    </tr>
  );
}
