import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import I18n from 'common/i18n';
import { ForgeButton, ForgeIcon } from '@tylertech/forge-react';
import { Dispatcher } from '../redux/actions';
import * as Actions from '../redux/actions';
import { OpenModalType } from '../redux/store';
import { none } from 'ts-option';
import { Tab } from 'common/explore_grid/types';

const t = (k: string, options = {}, scope = 'shared.explore_grid.parameters_editor') => I18n.t(k, { ...options, scope });

type DispatchProps = {
  openParameterModal: () => void;
};

type ExternalProps = {
  tab: Tab;
  canModifyParameters: boolean
};

type Props = DispatchProps & ExternalProps;

class AddParameterButton extends React.Component<Props> {
  render() {
    const { tab, canModifyParameters } = this.props;
    if (tab === Tab.Parameters && canModifyParameters) {
      return (<span className="datasource-show-modal-btn datasource-left-subtitle most-left-subtitle">
        <span className="bar">|</span>
        <ForgeButton dense>
          <button
            id={'new-parameter-button'}
            onClick={this.props.openParameterModal}>
            <ForgeIcon name="add"></ForgeIcon>
            {t('new_parameter')}
          </button>
        </ForgeButton>
      </span>);
    }
    return null;
  }
}

const mapDispatchToProps = (dispatch: Dispatcher): DispatchProps => {
  return {
    openParameterModal: () => {
      dispatch(Actions.openModal(OpenModalType.NEW_PARAMETER, none));
    }
  };
};

export default connect(null, mapDispatchToProps)(AddParameterButton);
