import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Option } from 'ts-option';
import { ForgeButton } from '@tylertech/forge-react';

import * as Actions from '../redux/actions';
import { Dispatcher } from '../redux/actions';

import I18n from 'common/i18n';
import { View } from 'common/types/view';

import { AppState, SaveStatus } from '../redux/store';

interface ExternalProps {
  view: View;
  newQueryString: Option<string>;
  querySucceeded: boolean;
}

interface StateProps {
  saveStatus: SaveStatus;
}
interface DispatchProps {
  saveView: (view: View, queryText: Option<string>) => void;
}

type Props = ExternalProps & DispatchProps & StateProps;

export class SaveViewButton extends Component<Props> {
  onClick = () => {
    const { view, newQueryString } = this.props;
    this.props.saveView(view, newQueryString);
  };

  isDisabled(): boolean {
    const { view, newQueryString, querySucceeded } = this.props;
    return (
      !querySucceeded || newQueryString.map((changed) => changed === view.queryString).getOrElseValue(true)
    );
  }

  render() {
    return (
      <ForgeButton type="raised" onClick={this.onClick}>
        <button type="button" disabled={this.isDisabled()}>
          {I18n.t('shared.components.asset_action_bar.save')}
        </button>
      </ForgeButton>
    );
  }
}

const mapStateToProps = (state: AppState, props: ExternalProps): StateProps => {
  return {
    saveStatus: state.saveInfo.saveStatus
  };
};

const mapDispatchToProps = (dispatch: Dispatcher): DispatchProps => {
  return {
    saveView: (view: View, queryText: Option<string>) => {
      queryText.forEach((query) => dispatch(Actions.saveViewQueryString(view, query)));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SaveViewButton);
