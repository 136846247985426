import React, { FunctionComponent, useState } from 'react';
import { isEmpty as _isEmpty } from 'lodash';

import I18n from 'common/i18n';
import { ClientContextVariable, Override } from 'common/types/clientContextVariable';
import { SoQLType } from 'common/types/soql';
import { ParameterInput } from './ParameterInput';

const t = (k: string, options = {}, scope = 'shared.explore_grid.parameters_editor.override_validation') =>
  I18n.t(k, { ...options, scope });

type OverrideInputProps = {
  parameter: ClientContextVariable;
  testValueDiffersCallback: (override: Override) => void;
};

const ParameterOverrideInput: FunctionComponent<OverrideInputProps> = ({ parameter, testValueDiffersCallback }: OverrideInputProps) => {
  const [override, setOverride] = useState<string>(parameter.overrideValue || parameter.defaultValue);
  const [validationError, setValidationError] = useState<string>('');

  const onChange = (newOverride: string) => {
    let error;
    if (parameter.dataType === SoQLType.SoQLFloatingTimestampT && _isEmpty(newOverride)) {
      // forge date picker passes invalid dates to us as null, so empty and invalid must be handled under one message
      error = t('invalid_date');
    } else if (_isEmpty(newOverride)) {
      error = t('cannot_be_empty');
    } else if (parameter.dataType === SoQLType.SoQLNumberT && isNaN(Number(newOverride))) {
      error = t('invalid_number');
    } else if (!_isEmpty(validationError)) {
      error = '';
    }
    if (newOverride !== override) testValueDiffersCallback({override: newOverride, isValid: _isEmpty(error)});
    if (error !== undefined) setValidationError(error);
    setOverride(newOverride);
  };
  const id = `${parameter.name}-override-input`;
  return (
    <ParameterInput
      label={parameter.displayName}
      id={id}
      dataType={parameter.dataType}
      value={override}
      readOnly={false}
      onChange={onChange}
      suggestedValuesType={parameter.suggestedValuesType}
      suggestedValues={parameter.suggestedValues?.valueList}
      validationError={validationError}
      additionalInputClasses='ec-parameter-override-input' // used by Pendo to track usage
    />
  );
};

export default ParameterOverrideInput;
